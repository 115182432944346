import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import CustomButton from "../../components/Helpers/CustomButton";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { openModal } from "../../store/modal/modalReducer";

import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import LoadingComponent from "../../components/Helpers/LoadingComponent";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function BookingCalendar(props) {
    const { current_staff } = useSelector((state) => state.auth);
    const { appointments } = useSelector((state) => state.appointments);
    const { settings } = useSelector((state) => state.settings);
    const { properties } = useSelector((state) => state.properties);
    const history = useHistory();
    const [allowClick, setAllowClick] = useState(true);
    const [bookings, setBookings] = useState(true);
    const [showBookings, setShowBookings] = useState(false);
    const [selectedCalendar, setSelectedCalendar] = useState("");
    const [bookingsLoading, setBookingsLoading] = useState(false);
    const localizer = momentLocalizer(moment);
    const dispatch = useDispatch();
    const [propertiesOption, setPropertiesOption] = useState([]);
    const [appointmentTypes, setAppointmentTypes] = useState([]);

    const [getPropList, setGetPropList] = useState(false);
    const [newBookings, setNewBookings] = useState(false);
    const [newBookingProp, setNewBookingProp] = useState("");

    useEffect(async () => {
        if (history.action === "POP" && allowClick === true) {
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}channels`,
            });
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}tagsList`,
            });
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}collectionList`,
            });
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}mentionList/${current_staff.id}`,
            });

            if (settings && settings.brokers === 1) {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}brokers`,
                });
            }

            if (settings && settings.renewals === 1) {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}renewalsItem`,
                });
            }

            setAllowClick(false);
        }

        if (getPropList === false) {
            setGetPropList(true);

            let propList = [];
            properties.forEach(function (property) {
                if (current_staff.is_plumber === 0 && current_staff.is_electrician === 0) {
                    if (property.value != "3" && property.value != "7") {
                        if (property.value == "6") {
                            property.label = property.label.split(" - ")[0];
                            propList.push(property);
                        } else {
                            propList.push(property);
                        }
                    }
                }
            });

            if (current_staff.is_plumber === 1 || current_staff.status === "Admin") {
                propList.push({ value: 100, label: "Plumbing Callout" });
            }
            if (current_staff.is_electrician === 1 || current_staff.status === "Admin") {
                propList.push({ value: 101, label: "Electrical Callout" });
            }

            setPropertiesOption(propList);
        }

        if (props.location.query?.property !== undefined) {
            setSelectedCalendar(props.location.query?.property);
            getBookings(props.location.query?.property);
        }

        if (newBookings === 1) {
            setNewBookings(0);
            getBookings(newBookingProp);
        }
    }, [newBookings, newBookingProp]);

    async function getBookings(property_id) {
        setShowBookings(true);
        setBookingsLoading(true);

        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}appointment_bookings/${property_id}`,
        }).then((response) => {
            console.log(response.data);
            setAppointmentTypes(response.data[1]);
            let appointment_bookings = response.data[0];
            let allBookings = [];

            async function processBookingss(array) {
                for (const booking of array) {
                    let nameType = booking?.booking?.property?.name.split(" - ")[1] == undefined ? "" : booking?.booking?.property?.name.split(" - ")[1] + " - ";
                    let bedType = booking?.booking?.bed?.name == undefined ? "" : booking?.booking?.bed?.name;
                    let managerName = booking?.manager?.name == undefined ? "" : " | " + booking?.manager?.name + " " + booking?.manager?.surname;
                    allBookings.push({
                        start: new Date(`${booking.booked_date} ${booking.booked_time || "00:00"}`),
                        end: new Date(`${booking.booked_date} ${booking.time_to || "23:59"}`),
                        userId: booking.appointment?.color == null ? "#f71505" : booking.appointment.color,
                        title:
                            booking.appointment_id === "-1"
                                ? "BM Meeting"
                                : (booking?.name ? booking.name : "Building General") + " | " + nameType + "Unit " + (booking?.unit?.name ? booking?.unit?.name + " " : " ? ") + bedType + managerName,
                        booking_id: booking.id,
                        booking_status: booking.status === 2 ? 1 : 0.4,
                    });
                }
            }
            processBookingss(appointment_bookings);
            setBookings(allBookings);
            setBookingsLoading(false);
        });
    }

    return (
        <>
            <div className="h-screen w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
                <h1 className="text-xl font-bold">Calendars</h1>

                <div className="mt-2">
                    <CustomButton
                        text="Create BM Meeting"
                        onClick={() =>
                            dispatch(
                                openModal({
                                    modal_type: "ModalBMAppointment",
                                    modal_props: {
                                        appointment_type: selectedCalendar,
                                        getBookings: getBookings,
                                        property_id: selectedCalendar,
                                    },
                                })
                            )
                        }
                    />
                </div>

                <div className="my-5">
                    {propertiesOption &&
                        propertiesOption.length > 0 &&
                        propertiesOption.map((property) => (
                            <>
                                {/* {console.log(current_staff.building_manager, current_staff.bm_property_id)} */}
                                {current_staff.building_manager === 0 || (current_staff.building_manager === 1 && current_staff.bm_property_id === property.value) ? (
                                    <p
                                        className={classNames(selectedCalendar === property.value ? "bg-gray-300" : "hover:bg-gray-300", "border p-2 cursor-pointer")}
                                        onClick={() => {
                                            setSelectedCalendar(property.value);
                                            getBookings(property.value);
                                        }}
                                    >
                                        {property.label}
                                    </p>
                                ) : (
                                    ""
                                )}
                            </>
                        ))}
                    {/* {appointments && appointments.length > 0 && appointments.map((appointment) => (
                        <>
                        {console.log(current_staff.building_manager, current_staff.bm_property_id)}
                            {(current_staff.building_manager === 0) || (current_staff.building_manager === 1 && current_staff.bm_property_id === appointment.property_id) ?
                            <p style={{color:appointment.color}} className={classNames(selectedCalendar === appointment.id ? "bg-gray-300" : "hover:bg-gray-300", "border p-2 cursor-pointer")} onClick={() => {setSelectedCalendar(appointment.id); getBookings(appointment.id)}}>{appointment.name}</p>
                            : "" }
                        </>
                    ))} */}
                </div>

                {showBookings === true ? (
                    <>
                        {bookingsLoading === true ? (
                            <>
                                <LoadingComponent />
                            </>
                        ) : (
                            <>
                                {selectedCalendar === 100 || selectedCalendar === 101 ? (
                                    <div class="flex flex-row-reverse mb-2">
                                        <CustomButton
                                            text="Create Appointment"
                                            onClick={() =>
                                                dispatch(
                                                    openModal({
                                                        modal_type: "ModalBookAppointment",
                                                        modal_props: {
                                                            appointment_type: selectedCalendar,
                                                            getBookings: getBookings,
                                                            property_id: selectedCalendar,
                                                        },
                                                    })
                                                )
                                            }
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <div class="flex flex-row-reverse mb-2">
                                            <CustomButton
                                                text="Create Electrical Callout"
                                                style={{ marginLeft: "5px" }}
                                                onClick={() =>
                                                    dispatch(
                                                        openModal({
                                                            modal_type: "ModalBookAppointment",
                                                            modal_props: {
                                                                appointment_type: 101,
                                                                getBookings: getBookings,
                                                                property_id: selectedCalendar,
                                                            },
                                                        })
                                                    )
                                                }
                                            />
                                            <CustomButton
                                                text="Create Plumbing Callout"
                                                onClick={() =>
                                                    dispatch(
                                                        openModal({
                                                            modal_type: "ModalBookAppointment",
                                                            modal_props: {
                                                                appointment_type: 100,
                                                                getBookings: getBookings,
                                                                property_id: selectedCalendar,
                                                            },
                                                        })
                                                    )
                                                }
                                            />
                                        </div>
                                    </>
                                )}

                                <Calendar
                                    onSelectEvent={(e) =>
                                        dispatch(
                                            openModal({
                                                modal_type: "ModalViewAppointment",
                                                modal_props: {
                                                    booking_id: e.booking_id,
                                                    setNewBookings: setNewBookings,
                                                    setNewBookingProp: setNewBookingProp,
                                                    getBookings: getBookings,
                                                    property_id: selectedCalendar,
                                                },
                                            })
                                        )
                                    }
                                    localizer={localizer}
                                    events={bookings}
                                    startAccessor="start"
                                    endAccessor="end"
                                    style={{ height: 500 }}
                                    eventPropGetter={(event) => {
                                        return {
                                            style: { backgroundColor: event.userId, opacity: event.booking_status },
                                        };
                                    }}
                                />
                                <br />
                                <div className="sm:flex">
                                    {appointmentTypes &&
                                        appointmentTypes.length > 0 &&
                                        appointmentTypes.map((type) => {
                                            return (
                                                <p className="mr-5 mb-5 inline-flex">
                                                    <span style={{ backgroundColor: type.color, height: "25px", width: "25px", borderRadius: "50%", display: "inline-block" }}> </span>
                                                    <span className="ml-1">{type.name}</span>
                                                </p>
                                            );
                                        })}
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <p>Please Select an Appointment type</p>
                )}
            </div>
        </>
    );
}
