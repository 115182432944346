import React, { Fragment, useState } from "react";
import { Formik, Form, FieldArray } from "formik";
import { Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import ErrorBoundary from "../../containers/ErrorBoundary";

import NotificationToast from "../Helpers/NotificationToast";
import SubmitButton from "../Forms/SubmitButton";
import CustomButton from "../Helpers/CustomButton";
import SettingsSelect from "../Forms/SettingsSelect";
import SettingsTextInput from "../Forms/SettingsTextInput";
import SettingsTextArea from "../Forms/SettingsTextarea";
import { closeModal } from "../../store/modal/modalReducer";

import { HexColorPicker } from "react-colorful";
import SettingsTimePicker from "../Forms/SettingsTimePicker";
import { getAppointments } from "../../store/appointments/appointmentActions";
import Select from "react-select";
import DatePicker from "react-datepicker";

export default function SlideoverAppointmentForm(props) {
    const dispatch = useDispatch();
    const [open] = useState(true);
    const { properties } = useSelector((state) => state.properties);
    const { managers } = useSelector((state) => state.managers);
    const [color, setColor] = useState("#aabbcc");
    const [dateType, setDateType] = useState("set");
    const [availableType, setAvailableType] = useState(1);
    const [daysType, setDaysType] = useState(1);
    const [beforeTime, setBeforeTime] = useState(false);
    const [afterTime, setAfterTime] = useState(false);
    const [showUnit, setShowUnit] = useState(true);
    const [onPropertyCalendar, setOnPropertyCalendar] = useState(true);
    const [propertiesOption, setPropertiesOption] = useState([]);
    const [setProp, setSetProp] = useState(false);

    const [firstWeek, setFirstWeek] = useState(true);
    const [secondWeek, setSecondWeek] = useState(true);
    const [thirdWeek, setThirdWeek] = useState(true);
    const [fourthWeek, setFourthWeek] = useState(true);

    const [monActive, setMonActive] = useState(true);
    const [tueActive, setTueActive] = useState(true);
    const [wedActive, setWedActive] = useState(true);
    const [thuActive, setThuActive] = useState(true);
    const [friActive, setFriActive] = useState(true);
    const [satActive, setSatActive] = useState(true);
    const [sunActive, setSunActive] = useState(true);

    const [dateRangeJan1, setDateRangeJan1] = useState([null, null]);
    const [startDateJan1, endDateJan1] = dateRangeJan1;
    const [dateRangeJan2, setDateRangeJan2] = useState([null, null]);
    const [startDateJan2, endDateJan2] = dateRangeJan2;

    const [dateRangeFeb1, setDateRangeFeb1] = useState([null, null]);
    const [startDateFeb1, endDateFeb1] = dateRangeFeb1;
    const [dateRangeFeb2, setDateRangeFeb2] = useState([null, null]);
    const [startDateFeb2, endDateFeb2] = dateRangeFeb2;

    const [dateRangeMar1, setDateRangeMar1] = useState([null, null]);
    const [startDateMar1, endDateMar1] = dateRangeMar1;
    const [dateRangeMar2, setDateRangeMar2] = useState([null, null]);
    const [startDateMar2, endDateMar2] = dateRangeMar2;

    const [dateRangeApr1, setDateRangeApr1] = useState([null, null]);
    const [startDateApr1, endDateApr1] = dateRangeApr1;
    const [dateRangeApr2, setDateRangeApr2] = useState([null, null]);
    const [startDateApr2, endDateApr2] = dateRangeApr2;

    const [dateRangeMay1, setDateRangeMay1] = useState([null, null]);
    const [startDateMay1, endDateMay1] = dateRangeMay1;
    const [dateRangeMay2, setDateRangeMay2] = useState([null, null]);
    const [startDateMay2, endDateMay2] = dateRangeMay2;

    const [dateRangeJun1, setDateRangeJun1] = useState([null, null]);
    const [startDateJun1, endDateJun1] = dateRangeJun1;
    const [dateRangeJun2, setDateRangeJun2] = useState([null, null]);
    const [startDateJun2, endDateJun2] = dateRangeJun2;

    const [dateRangeJul1, setDateRangeJul1] = useState([null, null]);
    const [startDateJul1, endDateJul1] = dateRangeJul1;
    const [dateRangeJul2, setDateRangeJul2] = useState([null, null]);
    const [startDateJul2, endDateJul2] = dateRangeJul2;

    const [dateRangeAug1, setDateRangeAug1] = useState([null, null]);
    const [startDateAug1, endDateAug1] = dateRangeAug1;
    const [dateRangeAug2, setDateRangeAug2] = useState([null, null]);
    const [startDateAug2, endDateAug2] = dateRangeAug2;

    const [dateRangeSept1, setDateRangeSept1] = useState([null, null]);
    const [startDateSept1, endDateSept1] = dateRangeSept1;
    const [dateRangeSept2, setDateRangeSept2] = useState([null, null]);
    const [startDateSept2, endDateSept2] = dateRangeSept2;

    const [dateRangeOct1, setDateRangeOct1] = useState([null, null]);
    const [startDateOct1, endDateOct1] = dateRangeOct1;
    const [dateRangeOct2, setDateRangeOct2] = useState([null, null]);
    const [startDateOct2, endDateOct2] = dateRangeOct2;

    const [dateRangeNov1, setDateRangeNov1] = useState([null, null]);
    const [startDateNov1, endDateNov1] = dateRangeNov1;
    const [dateRangeNov2, setDateRangeNov2] = useState([null, null]);
    const [startDateNov2, endDateNov2] = dateRangeNov2;

    const [dateRangeDec1, setDateRangeDec1] = useState([null, null]);
    const [startDateDec1, endDateDec1] = dateRangeDec1;
    const [dateRangeDec2, setDateRangeDec2] = useState([null, null]);
    const [startDateDec2, endDateDec2] = dateRangeDec2;

    const [disableInputs, setDisableInputs] = useState(true);

    const [managerOptions, setManagerOptions] = useState([]);

    let propList = [];
    propList.push({ value: 0, label: "All" });
    properties.forEach(function (property) {
        if (property.value != "3" && property.value != "7") {
            if (property.value == "6") {
                property.label = property.label.split(" - ")[0];
                propList.push(property);
            } else {
                propList.push(property);
            }
        }
    });

    if (setProp === false) {
        setSetProp(true);
        setPropertiesOption(propList);
    }

    async function getManagers(property_id) {
        let manList = [];
        managers.forEach(function (manager) {
            let schedules = manager.schedule;
            schedules.forEach(function (schedule) {
                if (
                    schedule.property_id == property_id ||
                    schedule.property_id == null
                ) {
                    manList.push({
                        value: manager.id,
                        label: manager.name + " " + manager.surname + " - " + manager.email,
                    });
                }
            });
        });
        setManagerOptions(manList);
    }

    const [iniSet, setIniSet] = useState(true);

    var initial_values,
        type,
        api_method,
        button = "";

    if (props.action == "edit") {
        let defList = [];
        if (props.data.data.managers !== null) {
            let preList = props.data.data.managers.split(",");
            managers.forEach(function (manager) {
                if (manager.schedule && manager.schedule.length > 0) {
                    let schedules = manager.schedule;
                    schedules.forEach(function (schedule) {
                        if (
                            schedule.property_id == props.data.data.property_id ||
                            (schedule.property_id === null &&
                                manager.is_electrician === 1 &&
                                props.data.data.name === "Electrical Callout") ||
                            (schedule.property_id === null &&
                                manager.is_plumber === 1 &&
                                props.data.data.name === "Plumbing Callout")
                        ) {
                            if (props.action == "edit") {
                                if (
                                    preList &&
                                    preList.length > 0 &&
                                    preList.includes(manager.id.toString())
                                ) {
                                    defList.push({
                                        value: manager.id,
                                        label:
                                            manager.name +
                                            " " +
                                            manager.surname +
                                            " - " +
                                            manager.email,
                                    });
                                }
                            }
                        }
                    });
                }
            });
        }

        initial_values = {
            id: props.data.data.id,
            property_id: props.data.data.property_id,
            name: props.data.data.name,
            description: props.data.data.description,
            link_url: props.data.data.link_url,
            link_shortcode: props.data.data.link_shortcode,
            color: props.data.data.color,
            date_range: props.data.data.date_range,
            available_type: props.data.data.available_type,
            days_amount: props.data.data.days_amount,
            minute_break: props.data.data.minute_break,
            days_type: props.data.data.days_type,
            duration: props.data.data.duration,
            mon_hours: props.data.data.mon_hours,
            mon_start: props.data.data.mon_start,
            mon_end: props.data.data.mon_end,
            tue_hours: props.data.data.tue_hours,
            tue_start: props.data.data.tue_start,
            tue_end: props.data.data.tue_end,
            wed_hours: props.data.data.wed_hours,
            wed_start: props.data.data.wed_start,
            wed_end: props.data.data.wed_end,
            thu_hours: props.data.data.thu_hours,
            thu_start: props.data.data.thu_start,
            thu_end: props.data.data.thu_end,
            fri_hours: props.data.data.fri_hours,
            fri_start: props.data.data.fri_start,
            fri_end: props.data.data.fri_end,
            sat_hours: props.data.data.sat_hours,
            sat_start: props.data.data.sat_start,
            sat_end: props.data.data.sat_end,
            sun_hours: props.data.data.sun_hours,
            sun_start: props.data.data.sun_start,
            sun_end: props.data.data.sun_end,
            before_appointment: props.data.data.before_appointment,
            time_before_appointment: props.data.data.time_before_appointment,
            after_appointment: props.data.data.after_appointment,
            time_after_appointment: props.data.data.time_after_appointment,
            blocked_dates:
                props.data.data.blocked !== null && [props.data.data.blocked].length > 0
                    ? props.data.data.blocked
                    : [],
            show_unit: props.data.data.show_unit,
            on_property_calendar: props.data.data.on_property_calendar,
            managers: defList,
            first_week: props.data.data.first_week,
            second_week: props.data.data.second_week,
            third_week: props.data.data.third_week,
            fourth_week: props.data.data.fourth_week,
        };

        if (iniSet === true) {
            setIniSet(false);
            getManagers(props.data.data.property_id);
            setColor(props.data.data.color);
            setDateType(props.data.data.date_range === 1 ? "set" : "free");
            setAvailableType(props.data.data.available_type);
            setDaysType(props.data.data.days_type);
            setMonActive(props.data.data.mon_hours === 1 ? true : false);
            setTueActive(props.data.data.tue_hours === 1 ? true : false);
            setWedActive(props.data.data.wed_hours === 1 ? true : false);
            setThuActive(props.data.data.thu_hours === 1 ? true : false);
            setFriActive(props.data.data.fri_hours === 1 ? true : false);
            setSatActive(props.data.data.sat_hours === 1 ? true : false);
            setSunActive(props.data.data.sun_hours === 1 ? true : false);
            setBeforeTime(props.data.data.before_appointment === 1 ? true : false);
            setAfterTime(props.data.data.after_appointment === 1 ? true : false);
            setShowUnit(props.data.data.show_unit === 1 ? true : false);
            setOnPropertyCalendar(
                props.data.data.on_property_calendar === 1 ? true : false
            );

            setFirstWeek(props.data.data.first_week === 1 ? true : false);
            setSecondWeek(props.data.data.second_week === 1 ? true : false);
            setThirdWeek(props.data.data.third_week === 1 ? true : false);
            setFourthWeek(props.data.data.fourth_week === 1 ? true : false);

            // if(props.data.data.available_type !== 1){
            if (props.data.data?.custom) {
                setDateRangeJan1([
                    props.data.data?.custom?.jan_from_date_1 === null
                        ? null
                        : new Date(props.data.data?.custom?.jan_from_date_1),
                    props.data.data?.custom?.jan_to_date_1 === null
                        ? null
                        : new Date(props.data.data?.custom?.jan_to_date_1),
                ]);
                setDateRangeJan2([
                    props.data.data?.custom?.jan_from_date_2 === null
                        ? null
                        : new Date(props.data.data?.custom?.jan_from_date_2),
                    props.data.data?.custom?.jan_to_date_2 === null
                        ? null
                        : new Date(props.data.data?.custom?.jan_to_date_2),
                ]);
                setDateRangeFeb1([
                    props.data.data?.custom?.feb_from_date_1 === null
                        ? null
                        : new Date(props.data.data?.custom?.feb_from_date_1),
                    props.data.data?.custom?.feb_to_date_1 === null
                        ? null
                        : new Date(props.data.data?.custom?.feb_to_date_1),
                ]);
                setDateRangeFeb2([
                    props.data.data?.custom?.feb_from_date_2 === null
                        ? null
                        : new Date(props.data.data?.custom?.feb_from_date_2),
                    props.data.data?.custom?.feb_to_date_2 === null
                        ? null
                        : new Date(props.data.data?.custom?.feb_to_date_2),
                ]);
                setDateRangeMar1([
                    props.data.data?.custom?.mar_from_date_1 === null
                        ? null
                        : new Date(props.data.data?.custom?.mar_from_date_1),
                    props.data.data?.custom?.mar_to_date_1 === null
                        ? null
                        : new Date(props.data.data?.custom?.mar_to_date_1),
                ]);
                setDateRangeMar2([
                    props.data.data?.custom?.mar_from_date_2 === null
                        ? null
                        : new Date(props.data.data?.custom?.mar_from_date_2),
                    props.data.data?.custom?.mar_to_date_2 === null
                        ? null
                        : new Date(props.data.data?.custom?.mar_to_date_2),
                ]);
                setDateRangeApr1([
                    props.data.data?.custom?.apr_from_date_1 === null
                        ? null
                        : new Date(props.data.data?.custom?.apr_from_date_1),
                    props.data.data?.custom?.apr_to_date_1 === null
                        ? null
                        : new Date(props.data.data?.custom?.apr_to_date_1),
                ]);
                setDateRangeApr2([
                    props.data.data?.custom?.apr_from_date_2 === null
                        ? null
                        : new Date(props.data.data?.custom?.apr_from_date_2),
                    props.data.data?.custom?.apr_to_date_2 === null
                        ? null
                        : new Date(props.data.data?.custom?.apr_to_date_2),
                ]);
                setDateRangeMay1([
                    props.data.data?.custom?.may_from_date_1 === null
                        ? null
                        : new Date(props.data.data?.custom?.may_from_date_1),
                    props.data.data?.custom?.may_to_date_1 === null
                        ? null
                        : new Date(props.data.data?.custom?.may_to_date_1),
                ]);
                setDateRangeMay2([
                    props.data.data?.custom?.may_from_date_2 === null
                        ? null
                        : new Date(props.data.data?.custom?.may_from_date_2),
                    props.data.data?.custom?.may_to_date_2 === null
                        ? null
                        : new Date(props.data.data?.custom?.may_to_date_2),
                ]);
                setDateRangeJun1([
                    props.data.data?.custom?.jun_from_date_1 === null
                        ? null
                        : new Date(props.data.data?.custom?.jun_from_date_1),
                    props.data.data?.custom?.jun_to_date_1 === null
                        ? null
                        : new Date(props.data.data?.custom?.jun_to_date_1),
                ]);
                setDateRangeJun2([
                    props.data.data?.custom?.jun_from_date_2 === null
                        ? null
                        : new Date(props.data.data?.custom?.jun_from_date_2),
                    props.data.data?.custom?.jun_to_date_2 === null
                        ? null
                        : new Date(props.data.data?.custom?.jun_to_date_2),
                ]);
                setDateRangeJul1([
                    props.data.data?.custom?.jul_from_date_1 === null
                        ? null
                        : new Date(props.data.data?.custom?.jul_from_date_1),
                    props.data.data?.custom?.jul_to_date_1 === null
                        ? null
                        : new Date(props.data.data?.custom?.jul_to_date_1),
                ]);
                setDateRangeJul2([
                    props.data.data?.custom?.jul_from_date_2 === null
                        ? null
                        : new Date(props.data.data?.custom?.jul_from_date_2),
                    props.data.data?.custom?.jul_to_date_2 === null
                        ? null
                        : new Date(props.data.data?.custom?.jul_to_date_2),
                ]);
                setDateRangeAug1([
                    props.data.data?.custom?.aug_from_date_1 === null
                        ? null
                        : new Date(props.data.data?.custom?.aug_from_date_1),
                    props.data.data?.custom?.aug_to_date_1 === null
                        ? null
                        : new Date(props.data.data?.custom?.aug_to_date_1),
                ]);
                setDateRangeAug2([
                    props.data.data?.custom?.aug_from_date_2 === null
                        ? null
                        : new Date(props.data.data?.custom?.aug_from_date_2),
                    props.data.data?.custom?.aug_to_date_2 === null
                        ? null
                        : new Date(props.data.data?.custom?.aug_to_date_2),
                ]);
                setDateRangeSept1([
                    props.data.data?.custom?.sept_from_date_1 === null
                        ? null
                        : new Date(props.data.data?.custom?.sept_from_date_1),
                    props.data.data?.custom?.sept_to_date_1 === null
                        ? null
                        : new Date(props.data.data?.custom?.sept_to_date_1),
                ]);
                setDateRangeSept2([
                    props.data.data?.custom?.sept_from_date_2 === null
                        ? null
                        : new Date(props.data.data?.custom?.sept_from_date_2),
                    props.data.data?.custom?.sept_to_date_2 === null
                        ? null
                        : new Date(props.data.data?.custom?.sept_to_date_2),
                ]);
                setDateRangeOct1([
                    props.data.data?.custom?.oct_from_date_1 === null
                        ? null
                        : new Date(props.data.data?.custom?.oct_from_date_1),
                    props.data.data?.custom?.oct_to_date_1 === null
                        ? null
                        : new Date(props.data.data?.custom?.oct_to_date_1),
                ]);
                setDateRangeOct2([
                    props.data.data?.custom?.oct_from_date_2 === null
                        ? null
                        : new Date(props.data.data?.custom?.oct_from_date_2),
                    props.data.data?.custom?.oct_to_date_2 === null
                        ? null
                        : new Date(props.data.data?.custom?.oct_to_date_2),
                ]);
                setDateRangeNov1([
                    props.data.data?.custom?.nov_from_date_1 === null
                        ? null
                        : new Date(props.data.data?.custom?.nov_from_date_1),
                    props.data.data?.custom?.nov_to_date_1 === null
                        ? null
                        : new Date(props.data.data?.custom?.nov_to_date_1),
                ]);
                setDateRangeNov2([
                    props.data.data?.custom?.nov_from_date_2 === null
                        ? null
                        : new Date(props.data.data?.custom?.nov_from_date_2),
                    props.data.data?.custom?.nov_to_date_2 === null
                        ? null
                        : new Date(props.data.data?.custom?.nov_to_date_2),
                ]);
                setDateRangeDec1([
                    props.data.data?.custom?.dec_from_date_1 === null
                        ? null
                        : new Date(props.data.data?.custom?.dec_from_date_1),
                    props.data.data?.custom?.dec_to_date_1 === null
                        ? null
                        : new Date(props.data.data?.custom?.dec_to_date_1),
                ]);
                setDateRangeDec2([
                    props.data.data?.custom?.dec_from_date_2 === null
                        ? null
                        : new Date(props.data.data?.custom?.dec_from_date_2),
                    props.data.data?.custom?.dec_to_date_2 === null
                        ? null
                        : new Date(props.data.data?.custom?.dec_to_date_2),
                ]);
            }
            // }

            setDisableInputs(true);
        }

        type = "Editing " + props.data.data.name;
        api_method = "appointment_update";
        button = "Update";
    } else {
        initial_values = {
            id: "",
            property_id: "",
            name: "",
            description: "",
            link_url: "",
            link_shortcode: "",
            color: "",
            date_range: 1,
            available_type: 1,
            days_amount: "",
            minute_break: 15,
            days_type: 1,
            duration: 1,
            mon_hours: 1,
            mon_start: "09:00",
            mon_end: "17:00",
            tue_hours: 1,
            tue_start: "09:00",
            tue_end: "17:00",
            wed_hours: 1,
            wed_start: "09:00",
            wed_end: "17:00",
            thu_hours: 1,
            thu_start: "09:00",
            thu_end: "17:00",
            fri_hours: 1,
            fri_start: "09:00",
            fri_end: "17:00",
            sat_hours: 1,
            sat_start: "09:00",
            sat_end: "17:00",
            sun_hours: 1,
            sun_start: "09:00",
            sun_end: "17:00",
            before_appointment: 0,
            time_before_appointment: 1,
            after_appointment: 0,
            time_after_appointment: 1,
            blocked_dates: [],
            show_unit: true,
            on_property_calendar: true,
            managers: [],
        };

        if (iniSet === true) {
            setIniSet(false);
            setDisableInputs(false);
        }

        type = "Create an " + props.data.type;
        api_method = "appointment_update";
        button = "Create";
    }

    async function makeNewDates() {
        //
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-50"
                onClose={() => dispatch(closeModal())}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <ErrorBoundary>
                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                        <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                            <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll bg-light_background">
                                                <div className="bg-dark_background py-6">
                                                    <div className=" px-4 sm:px-6">
                                                        <div className="flex items-start justify-between">
                                                            <Dialog.Title className="text-xl leading-6 font-light text-white uppercase">
                                                                {type}{" "}
                                                            </Dialog.Title>
                                                            <div className="ml-3 flex h-7 items-center">
                                                                <button
                                                                    type="button"
                                                                    className="rounded-md text-white hover:text-gray-200"
                                                                    onClick={() => dispatch(closeModal())}
                                                                >
                                                                    <span className="sr-only">Close panel</span>
                                                                    <FontAwesomeIcon
                                                                        className="h-6 w-6"
                                                                        aria-hidden="true"
                                                                        icon={faTimes}
                                                                    />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="relative mt-6 flex-1 px-4 sm:px-6 ">
                                                    <Formik
                                                        enableReinitialize={true}
                                                        initialValues={initial_values}
                                                        validationSchema={Yup.object({
                                                            name: Yup.string().required(
                                                                "You need to type in a name"
                                                            ),
                                                            property_id: Yup.string().required(
                                                                "Please choose a Property"
                                                            ),
                                                            link_url: Yup.string().required(
                                                                "You need to type in a link"
                                                            ),
                                                            link_shortcode: Yup.string().required(
                                                                "You need to type in a short code"
                                                            ),
                                                            days_amount: Yup.string().required(
                                                                "You need to type an amount of days"
                                                            ),
                                                        })}
                                                        onSubmit={async (
                                                            values,
                                                            { setSubmitting, setErrors, resetForm }
                                                        ) => {
                                                            setSubmitting(true);

                                                            values.on_property_calendar = onPropertyCalendar;
                                                            values.show_unit = showUnit;

                                                            values.first_week = firstWeek;
                                                            values.second_week = secondWeek;
                                                            values.third_week = thirdWeek;
                                                            values.fourth_week = fourthWeek;

                                                            if (availableType !== 1) {
                                                                values.dateRangeJan1 = dateRangeJan1;
                                                                values.dateRangeJan2 = dateRangeJan2;
                                                                values.dateRangeFeb1 = dateRangeFeb1;
                                                                values.dateRangeFeb2 = dateRangeFeb2;
                                                                values.dateRangeMar1 = dateRangeMar1;
                                                                values.dateRangeMar2 = dateRangeMar2;
                                                                values.dateRangeApr1 = dateRangeApr1;
                                                                values.dateRangeApr2 = dateRangeApr2;
                                                                values.dateRangeMay1 = dateRangeMay1;
                                                                values.dateRangeMay2 = dateRangeMay2;
                                                                values.dateRangeJun1 = dateRangeJun1;
                                                                values.dateRangeJun2 = dateRangeJun2;
                                                                values.dateRangeJul1 = dateRangeJul1;
                                                                values.dateRangeJul2 = dateRangeJul2;
                                                                values.dateRangeAug1 = dateRangeAug1;
                                                                values.dateRangeAug2 = dateRangeAug2;
                                                                values.dateRangeSept1 = dateRangeSept1;
                                                                values.dateRangeSept2 = dateRangeSept2;
                                                                values.dateRangeOct1 = dateRangeOct1;
                                                                values.dateRangeOct2 = dateRangeOct2;
                                                                values.dateRangeNov1 = dateRangeNov1;
                                                                values.dateRangeNov2 = dateRangeNov2;
                                                                values.dateRangeDec1 = dateRangeDec1;
                                                                values.dateRangeDec2 = dateRangeDec2;
                                                            }

                                                            await axios
                                                                .post(
                                                                    process.env.REACT_APP_API_URL + api_method,
                                                                    values
                                                                )
                                                                .then(() => {
                                                                    toast(
                                                                        <NotificationToast
                                                                            title={values.name + " saved"}
                                                                            message=""
                                                                        />
                                                                    );
                                                                })
                                                                .catch((error) => {
                                                                    toast(
                                                                        <NotificationToast
                                                                            title="Something went wrong"
                                                                            message={error.response}
                                                                            type="error"
                                                                        />
                                                                    );
                                                                })
                                                                .finally(() => {
                                                                    dispatch(closeModal());
                                                                    resetForm();
                                                                    dispatch(getAppointments());
                                                                });
                                                        }}
                                                    >
                                                        {({
                                                              values,
                                                              errors,
                                                              onChange,
                                                              isValid,
                                                              dirty,
                                                              handleSubmit,
                                                              setFieldValue,
                                                              isSubmitting,
                                                              handleChange,
                                                              setValues,
                                                          }) => (
                                                            <Form
                                                                onSubmit={handleSubmit}
                                                                className="space-y-8"
                                                            >
                                                                <SettingsSelect
                                                                    label="Property"
                                                                    name="property_id"
                                                                    errors={errors.property_id}
                                                                    options={propertiesOption}
                                                                    getManagers={getManagers}
                                                                    // getManagers={getManagers(values.property_id)}
                                                                />
                                                                <Select
                                                                    defaultValue={values.managers}
                                                                    options={managerOptions}
                                                                    isMulti
                                                                    name="managers"
                                                                    onChange={(v) => setFieldValue("managers", v)}
                                                                />
                                                                <div className="w-full">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="mr-2"
                                                                        name="show_unit"
                                                                        checked={showUnit}
                                                                        onChange={(v) => {
                                                                            setShowUnit(v.target.checked);
                                                                            setFieldValue(
                                                                                "show_unit",
                                                                                v.target.checked
                                                                            );
                                                                        }}
                                                                    />{" "}
                                                                    Show Unit in Address
                                                                </div>
                                                                <div className="w-full">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="mr-2"
                                                                        name="on_property_calendar"
                                                                        checked={onPropertyCalendar}
                                                                        onChange={(v) => {
                                                                            setOnPropertyCalendar(v.target.checked);
                                                                            setFieldValue(
                                                                                "on_property_calendar",
                                                                                v.target.checked
                                                                            );
                                                                        }}
                                                                    />{" "}
                                                                    On property Calendar
                                                                    <br />
                                                                    <small>
                                                                        Unchecking this will allow scheduling
                                                                        overlapping appointments.
                                                                    </small>
                                                                </div>
                                                                <SettingsTextInput
                                                                    label="Name"
                                                                    name="name"
                                                                    placeholder="Name"
                                                                    errors={errors.name}
                                                                    value={values.name}
                                                                    disabled={disableInputs}
                                                                />
                                                                <SettingsTextArea
                                                                    name="description"
                                                                    placeholder="Description"
                                                                    errors={errors.description}
                                                                    emojis={true}
                                                                    setFieldValue={setFieldValue}
                                                                    handleChange={handleChange}
                                                                    value={values.description}
                                                                    wysiwyg={false}
                                                                />
                                                                <SettingsTextInput
                                                                    name="link_url"
                                                                    label="Appointment Link URL"
                                                                    placeholder="Appointment Link"
                                                                    errors={errors.link_url}
                                                                    value={values.link_url}
                                                                    disabled={disableInputs}
                                                                />
                                                                <small>e.g: move-in-form</small>
                                                                <SettingsTextInput
                                                                    name="link_shortcode"
                                                                    label="Appointment Short Code"
                                                                    placeholder="Short Code"
                                                                    errors={errors.link_shortcode}
                                                                    value={values.link_shortcode}
                                                                    disabled={disableInputs}
                                                                />
                                                                <small>e.g: move_in_form</small>
                                                                <label className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                    <span>Appointment Colour</span>
                                                                </label>
                                                                <HexColorPicker
                                                                    color={color}
                                                                    onChange={(value) =>
                                                                        setFieldValue("color", value)
                                                                    }
                                                                    name="color"
                                                                />
                                                                <label className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                    <span>Date Range</span>
                                                                </label>
                                                                <input
                                                                    type="radio"
                                                                    value="1"
                                                                    name="date_range"
                                                                    checked={dateType === "set" ? true : false}
                                                                    onChange={(v) => {
                                                                        if (v.target.checked === true) {
                                                                            setDateType("set");
                                                                        }
                                                                        setFieldValue("date_range", 1);
                                                                    }}
                                                                />
                                                                {dateType === "set" ? (
                                                                    <>
                                                                        <input
                                                                            type="number"
                                                                            name="days_amount"
                                                                            min="0"
                                                                            value={values.days_amount}
                                                                            className="w-10 ml-2 rounded-lg border-gray-300 shadow-sm focus:outline-primary h-9"
                                                                            onChange={(v) =>
                                                                                setFieldValue(
                                                                                    "days_amount",
                                                                                    v.target.value
                                                                                )
                                                                            }
                                                                        />
                                                                        <select
                                                                            name="days_type"
                                                                            onChange={(e) => {
                                                                                setDaysType(e.target.value);
                                                                                setFieldValue(
                                                                                    "days_type",
                                                                                    e.target.value
                                                                                );
                                                                            }}
                                                                            className="border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
                                                                        >
                                                                            <option
                                                                                value="1"
                                                                                selected={daysType === 1 ? true : false}
                                                                            >
                                                                                Calendar Days
                                                                            </option>
                                                                            {/* <option value="2" selected={daysType === 2 ? true : false}>Business Days</option> */}
                                                                        </select>
                                                                    </>
                                                                ) : (
                                                                    ""
                                                                )}{" "}
                                                                into the future
                                                                <p className="text-red-400 text-sm font-light">
                                                                    {errors.days_amount}
                                                                </p>
                                                                <br />
                                                                {/* <input type="radio" name="date_range" value="2" checked={dateType === "free" ? true : false} onChange={(v) => {if(v.target.checked === true){setDateType("free")}; setFieldValue("date_range", 2)}}/> Indefinitely into the future  */}
                                                                <br />
                                                                <br />
                                                                <label className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                    <span>Duration</span>
                                                                </label>
                                                                <select
                                                                    name="duration"
                                                                    onChange={(e) =>
                                                                        setFieldValue("duration", e.target.value)
                                                                    }
                                                                    className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2 w-full"
                                                                >
                                                                    <option
                                                                        value="1"
                                                                        selected={
                                                                            values.duration === 1 ? true : false
                                                                        }
                                                                    >
                                                                        15 min
                                                                    </option>
                                                                    <option
                                                                        value="2"
                                                                        selected={
                                                                            values.duration === 2 ? true : false
                                                                        }
                                                                    >
                                                                        30 min
                                                                    </option>
                                                                    <option
                                                                        value="3"
                                                                        selected={
                                                                            values.duration === 3 ? true : false
                                                                        }
                                                                    >
                                                                        45 min
                                                                    </option>
                                                                    <option
                                                                        value="4"
                                                                        selected={
                                                                            values.duration === 4 ? true : false
                                                                        }
                                                                    >
                                                                        1 hour
                                                                    </option>
                                                                    <option
                                                                        value="5"
                                                                        selected={
                                                                            values.duration === 5 ? true : false
                                                                        }
                                                                    >
                                                                        1 hour 15 min
                                                                    </option>
                                                                    <option
                                                                        value="6"
                                                                        selected={
                                                                            values.duration === 6 ? true : false
                                                                        }
                                                                    >
                                                                        1 hour 30 min
                                                                    </option>
                                                                    <option
                                                                        value="7"
                                                                        selected={
                                                                            values.duration === 7 ? true : false
                                                                        }
                                                                    >
                                                                        1 hour 45 min
                                                                    </option>
                                                                    <option
                                                                        value="8"
                                                                        selected={
                                                                            values.duration === 8 ? true : false
                                                                        }
                                                                    >
                                                                        2 hours
                                                                    </option>
                                                                    <option
                                                                        value="9"
                                                                        selected={
                                                                            values.duration === 9 ? true : false
                                                                        }
                                                                    >
                                                                        Full Day
                                                                    </option>
                                                                </select>
                                                                <br/>
                                                                <br/>
                                                                <label
                                                                    className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                    <span>Hours</span>
                                                                </label>
                                                                <div className="divide-y divide-slate-200">
                                                                <div className="py-5 flex">
                                                                        <div className="w-20">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="mr-2"
                                                                                name="mon_hours"
                                                                                checked={monActive}
                                                                                onChange={(v) => {
                                                                                    setMonActive(v.target.checked);
                                                                                    setFieldValue(
                                                                                        "mon_hours",
                                                                                        v.target.checked === true ? 1 : 0
                                                                                    );
                                                                                }}
                                                                            />{" "}
                                                                            MON
                                                                        </div>{" "}
                                                                        {monActive === true ? (
                                                                            <>
                                                                                <SettingsTimePicker
                                                                                    name="mon_start"
                                                                                    selected={values.mon_start}
                                                                                />{" "}
                                                                                -{" "}
                                                                                <SettingsTimePicker
                                                                                    name="mon_end"
                                                                                    selected={values.mon_end}
                                                                                />
                                                                            </>
                                                                        ) : (
                                                                            "Unavailable"
                                                                        )}
                                                                    </div>
                                                                    <div className="py-5 flex">
                                                                        <div className="w-20">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="mr-2"
                                                                                name="tue_hours"
                                                                                checked={tueActive}
                                                                                onChange={(v) => {
                                                                                    setTueActive(v.target.checked);
                                                                                    setFieldValue(
                                                                                        "tue_hours",
                                                                                        v.target.checked === true ? 1 : 0
                                                                                    );
                                                                                }}
                                                                            />{" "}
                                                                            TUE
                                                                        </div>{" "}
                                                                        {tueActive === true ? (
                                                                            <>
                                                                                <SettingsTimePicker
                                                                                    name="tue_start"
                                                                                    selected={values.tue_start}
                                                                                />{" "}
                                                                                -{" "}
                                                                                <SettingsTimePicker
                                                                                    name="tue_end"
                                                                                    selected={values.tue_end}
                                                                                />
                                                                            </>
                                                                        ) : (
                                                                            "Unavailable"
                                                                        )}
                                                                    </div>
                                                                    <div className="py-5 flex">
                                                                        <div className="w-20">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="mr-2"
                                                                                name="wed_hours"
                                                                                checked={wedActive}
                                                                                onChange={(v) => {
                                                                                    setWedActive(v.target.checked);
                                                                                    setFieldValue(
                                                                                        "wed_hours",
                                                                                        v.target.checked === true ? 1 : 0
                                                                                    );
                                                                                }}
                                                                            />{" "}
                                                                            WED
                                                                        </div>{" "}
                                                                        {wedActive === true ? (
                                                                            <>
                                                                                <SettingsTimePicker
                                                                                    name="wed_start"
                                                                                    selected={values.wed_start}
                                                                                />{" "}
                                                                                -{" "}
                                                                                <SettingsTimePicker
                                                                                    name="wed_end"
                                                                                    selected={values.wed_end}
                                                                                />
                                                                            </>
                                                                        ) : (
                                                                            "Unavailable"
                                                                        )}
                                                                    </div>
                                                                    <div className="py-5 flex">
                                                                        <div className="w-20">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="mr-2"
                                                                                name="thu_hours"
                                                                                checked={thuActive}
                                                                                onChange={(v) => {
                                                                                    setThuActive(v.target.checked);
                                                                                    setFieldValue(
                                                                                        "thu_hours",
                                                                                        v.target.checked === true ? 1 : 0
                                                                                    );
                                                                                }}
                                                                            />{" "}
                                                                            THU
                                                                        </div>{" "}
                                                                        {thuActive === true ? (
                                                                            <>
                                                                                <SettingsTimePicker
                                                                                    name="thu_start"
                                                                                    selected={values.thu_start}
                                                                                />{" "}
                                                                                -{" "}
                                                                                <SettingsTimePicker
                                                                                    name="thu_end"
                                                                                    selected={values.thu_end}
                                                                                />
                                                                            </>
                                                                        ) : (
                                                                            "Unavailable"
                                                                        )}
                                                                    </div>
                                                                    <div className="py-5 flex">
                                                                        <div className="w-20">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="mr-2"
                                                                                name="fri_hours"
                                                                                checked={friActive}
                                                                                onChange={(v) => {
                                                                                    setFriActive(v.target.checked);
                                                                                    setFieldValue(
                                                                                        "fri_hours",
                                                                                        v.target.checked === true ? 1 : 0
                                                                                    );
                                                                                }}
                                                                            />{" "}
                                                                            FRI
                                                                        </div>{" "}
                                                                        {friActive === true ? (
                                                                            <>
                                                                                <SettingsTimePicker
                                                                                    name="fri_start"
                                                                                    selected={values.fri_start}
                                                                                />{" "}
                                                                                -{" "}
                                                                                <SettingsTimePicker
                                                                                    name="fri_end"
                                                                                    selected={values.fri_end}
                                                                                />
                                                                            </>
                                                                        ) : (
                                                                            "Unavailable"
                                                                        )}
                                                                    </div>
                                                                    <div className="py-5 flex">
                                                                        <div className="w-20">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="mr-2"
                                                                                name="sat_hours"
                                                                                checked={satActive}
                                                                                onChange={(v) => {
                                                                                    setSatActive(v.target.checked);
                                                                                    setFieldValue(
                                                                                        "sat_hours",
                                                                                        v.target.checked === true ? 1 : 0
                                                                                    );
                                                                                }}
                                                                            />{" "}
                                                                            SAT
                                                                        </div>{" "}
                                                                        {satActive === true ? (
                                                                            <>
                                                                                <SettingsTimePicker
                                                                                    name="sat_start"
                                                                                    selected={values.sat_start}
                                                                                />{" "}
                                                                                -{" "}
                                                                                <SettingsTimePicker
                                                                                    name="sat_end"
                                                                                    selected={values.sat_end}
                                                                                />
                                                                            </>
                                                                        ) : (
                                                                            "Unavailable"
                                                                        )}
                                                                    </div>
                                                                    <div className="py-5 flex">
                                                                        <div className="w-20">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="mr-2"
                                                                                name="sun_hours"
                                                                                checked={sunActive}
                                                                                onChange={(v) => {
                                                                                    setSunActive(v.target.checked);
                                                                                    setFieldValue(
                                                                                        "sun_hours",
                                                                                        v.target.checked === true ? 1 : 0
                                                                                    );
                                                                                }}
                                                                            />{" "}
                                                                            SUN
                                                                        </div>{" "}
                                                                        {sunActive === true ? (
                                                                            <>
                                                                                <SettingsTimePicker
                                                                                    name="sun_start"
                                                                                    selected={values.sun_start}
                                                                                />{" "}
                                                                                -{" "}
                                                                                <SettingsTimePicker
                                                                                    name="sun_end"
                                                                                    selected={values.sun_end}
                                                                                />
                                                                            </>
                                                                        ) : (
                                                                            "Unavailable"
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <label className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                    <span>Breaks between appointments</span>
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    name="minute_break"
                                                                    min="0"
                                                                    value={values.minute_break}
                                                                    className="w-12 ml-2 rounded-lg border-gray-300 shadow-sm focus:outline-primary h-9"
                                                                    onChange={(v) =>
                                                                        setFieldValue(
                                                                            "minute_break",
                                                                            v.target.value
                                                                        )
                                                                    }
                                                                />{" "}
                                                                Minutes
                                                                <br />
                                                                <br />
                                                                <br />
                                                                <label className="text-sm font-medium mb-2 text-gray-700 items-baseline justify-between">
                                                                    <span>Availble Weeks</span>
                                                                    <br />
                                                                    <small>
                                                                        Please tick weeks that appoints are
                                                                        available for
                                                                    </small>
                                                                </label>
                                                                <br />
                                                                <label>
                                                                    <input
                                                                        type="radio"
                                                                        value="1"
                                                                        name="available_type"
                                                                        checked={availableType === 1 ? true : false}
                                                                        onChange={(v) => {
                                                                            if (v.target.checked === true) {
                                                                                setAvailableType(1);
                                                                            }
                                                                            setFieldValue("available_type", 1);
                                                                        }}
                                                                    />{" "}
                                                                    Weeks
                                                                </label>
                                                                <br />
                                                                <label>
                                                                    <input
                                                                        type="radio"
                                                                        value="2"
                                                                        name="available_type"
                                                                        checked={availableType === 2 ? true : false}
                                                                        onChange={(v) => {
                                                                            if (v.target.checked === true) {
                                                                                setAvailableType(2);
                                                                            }
                                                                            setFieldValue("available_type", 2);
                                                                        }}
                                                                    />{" "}
                                                                    Custom
                                                                </label>
                                                                {availableType === 1 ? (
                                                                    <>
                                                                        <div className="w-full">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="mr-2"
                                                                                name="first_week"
                                                                                checked={firstWeek}
                                                                                onChange={(v) => {
                                                                                    setFirstWeek(v.target.checked);
                                                                                    setFieldValue(
                                                                                        "first_week",
                                                                                        v.target.checked
                                                                                    );
                                                                                }}
                                                                            />{" "}
                                                                            First week of the month
                                                                        </div>
                                                                        <div className="w-full">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="mr-2"
                                                                                name="second_week"
                                                                                checked={secondWeek}
                                                                                onChange={(v) => {
                                                                                    setSecondWeek(v.target.checked);
                                                                                    setFieldValue(
                                                                                        "second_week",
                                                                                        v.target.checked
                                                                                    );
                                                                                }}
                                                                            />{" "}
                                                                            Second week of the month
                                                                        </div>
                                                                        <div className="w-full">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="mr-2"
                                                                                name="third_week"
                                                                                checked={thirdWeek}
                                                                                onChange={(v) => {
                                                                                    setThirdWeek(v.target.checked);
                                                                                    setFieldValue(
                                                                                        "third_week",
                                                                                        v.target.checked
                                                                                    );
                                                                                }}
                                                                            />{" "}
                                                                            Third week of the month
                                                                        </div>
                                                                        <div className="w-full">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="mr-2"
                                                                                name="fourth_week"
                                                                                checked={fourthWeek}
                                                                                onChange={(v) => {
                                                                                    setFourthWeek(v.target.checked);
                                                                                    setFieldValue(
                                                                                        "fourth_week",
                                                                                        v.target.checked
                                                                                    );
                                                                                }}
                                                                            />{" "}
                                                                            Fourth week of the month
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <br />
                                                                        <div>
                                                                            <div>
                                                                                <label className="text-sm font-medium mt-2 text-gray-700">
                                                                                    <span>January Section 1</span>
                                                                                </label>
                                                                                <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={startDateJan1}
                                                                                    endDate={endDateJan1}
                                                                                    onChange={(update) => {
                                                                                        setDateRangeJan1(update);
                                                                                    }}
                                                                                    dateFormat={"d MMMM yyyy"}
                                                                                    isClearable={true}
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    className="z-20 block w-full font-light border border-gray-300 shadow-sm !mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary space-y-2"
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <label className="text-sm font-medium mt-5 text-gray-700">
                                                                                    <span>January Section 2</span>
                                                                                </label>
                                                                                <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={startDateJan2}
                                                                                    endDate={endDateJan2}
                                                                                    onChange={(update) => {
                                                                                        setDateRangeJan2(update);
                                                                                    }}
                                                                                    dateFormat={"d MMMM yyyy"}
                                                                                    isClearable={true}
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                                                />
                                                                            </div>
                                                                            <div className="mt-4">
                                                                                <label className="text-sm font-medium mt-5 text-gray-700">
                                                                                    <span>February Section 1</span>
                                                                                </label>
                                                                                <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={startDateFeb1}
                                                                                    endDate={endDateFeb1}
                                                                                    onChange={(update) => {
                                                                                        setDateRangeFeb1(update);
                                                                                    }}
                                                                                    dateFormat={"d MMMM yyyy"}
                                                                                    isClearable={true}
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <label className="text-sm font-medium mt-5 text-gray-700">
                                                                                    <span>February Section 2</span>
                                                                                </label>
                                                                                <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={startDateFeb2}
                                                                                    endDate={endDateFeb2}
                                                                                    onChange={(update) => {
                                                                                        setDateRangeFeb2(update);
                                                                                    }}
                                                                                    dateFormat={"d MMMM yyyy"}
                                                                                    isClearable={true}
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                                                />
                                                                            </div>

                                                                            <div className="mt-4">
                                                                                <label className="text-sm font-medium mt-5 text-gray-700">
                                                                                    <span>March Section 1</span>
                                                                                </label>
                                                                                <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={startDateMar1}
                                                                                    endDate={endDateMar1}
                                                                                    onChange={(update) => {
                                                                                        setDateRangeMar1(update);
                                                                                    }}
                                                                                    dateFormat={"d MMMM yyyy"}
                                                                                    isClearable={true}
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <label className="text-sm font-medium mt-5 text-gray-700">
                                                                                    <span>March Section 2</span>
                                                                                </label>
                                                                                <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={startDateMar2}
                                                                                    endDate={endDateMar2}
                                                                                    onChange={(update) => {
                                                                                        setDateRangeMar2(update);
                                                                                    }}
                                                                                    dateFormat={"d MMMM yyyy"}
                                                                                    isClearable={true}
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                                                />
                                                                            </div>

                                                                            <div className="mt-4">
                                                                                <label className="text-sm font-medium mt-5 text-gray-700">
                                                                                    <span>June Section 1</span>
                                                                                </label>
                                                                                <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={startDateJun1}
                                                                                    endDate={endDateJun1}
                                                                                    onChange={(update) => {
                                                                                        setDateRangeJun1(update);
                                                                                    }}
                                                                                    dateFormat={"d MMMM yyyy"}
                                                                                    isClearable={true}
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <label className="text-sm font-medium mt-5 text-gray-700">
                                                                                    <span>June Section 2</span>
                                                                                </label>
                                                                                <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={startDateJun2}
                                                                                    endDate={endDateJun2}
                                                                                    onChange={(update) => {
                                                                                        setDateRangeJun2(update);
                                                                                    }}
                                                                                    dateFormat={"d MMMM yyyy"}
                                                                                    isClearable={true}
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                                                />
                                                                            </div>

                                                                            <div className="mt-4">
                                                                                <label className="text-sm font-medium mt-5 text-gray-700">
                                                                                    <span>July Section 1</span>
                                                                                </label>
                                                                                <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={startDateJul1}
                                                                                    endDate={endDateJul1}
                                                                                    onChange={(update) => {
                                                                                        setDateRangeJul1(update);
                                                                                    }}
                                                                                    dateFormat={"d MMMM yyyy"}
                                                                                    isClearable={true}
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <label className="text-sm font-medium mt-5 text-gray-700">
                                                                                    <span>July Section 2</span>
                                                                                </label>
                                                                                <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={startDateJul2}
                                                                                    endDate={endDateJul2}
                                                                                    onChange={(update) => {
                                                                                        setDateRangeJul2(update);
                                                                                    }}
                                                                                    dateFormat={"d MMMM yyyy"}
                                                                                    isClearable={true}
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                                                />
                                                                            </div>

                                                                            <div className="mt-4">
                                                                                <label className="text-sm font-medium mt-5 text-gray-700">
                                                                                    <span>August Section 1</span>
                                                                                </label>
                                                                                <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={startDateAug1}
                                                                                    endDate={endDateAug1}
                                                                                    onChange={(update) => {
                                                                                        setDateRangeAug1(update);
                                                                                    }}
                                                                                    dateFormat={"d MMMM yyyy"}
                                                                                    isClearable={true}
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <label className="text-sm font-medium mt-5 text-gray-700">
                                                                                    <span>August Section 2</span>
                                                                                </label>
                                                                                <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={startDateAug2}
                                                                                    endDate={endDateAug2}
                                                                                    onChange={(update) => {
                                                                                        setDateRangeAug2(update);
                                                                                    }}
                                                                                    dateFormat={"d MMMM yyyy"}
                                                                                    isClearable={true}
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                                                />
                                                                            </div>

                                                                            <div className="mt-4">
                                                                                <label className="text-sm font-medium mt-5 text-gray-700">
                                                                                    <span>September Section 1</span>
                                                                                </label>
                                                                                <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={startDateSept1}
                                                                                    endDate={endDateSept1}
                                                                                    onChange={(update) => {
                                                                                        setDateRangeSept1(update);
                                                                                    }}
                                                                                    dateFormat={"d MMMM yyyy"}
                                                                                    isClearable={true}
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <label className="text-sm font-medium mt-5 text-gray-700">
                                                                                    <span>September Section 2</span>
                                                                                </label>
                                                                                <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={startDateSept2}
                                                                                    endDate={endDateSept2}
                                                                                    onChange={(update) => {
                                                                                        setDateRangeSept2(update);
                                                                                    }}
                                                                                    dateFormat={"d MMMM yyyy"}
                                                                                    isClearable={true}
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                                                />
                                                                            </div>

                                                                            <div className="mt-4">
                                                                                <label className="text-sm font-medium mt-5 text-gray-700">
                                                                                    <span>October Section 1</span>
                                                                                </label>
                                                                                <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={startDateOct1}
                                                                                    endDate={endDateOct1}
                                                                                    onChange={(update) => {
                                                                                        setDateRangeOct1(update);
                                                                                    }}
                                                                                    dateFormat={"d MMMM yyyy"}
                                                                                    isClearable={true}
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <label className="text-sm font-medium mt-5 text-gray-700">
                                                                                    <span>October Section 2</span>
                                                                                </label>
                                                                                <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={startDateOct2}
                                                                                    endDate={endDateOct2}
                                                                                    onChange={(update) => {
                                                                                        setDateRangeOct2(update);
                                                                                    }}
                                                                                    dateFormat={"d MMMM yyyy"}
                                                                                    isClearable={true}
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                                                />
                                                                            </div>

                                                                            <div className="mt-4">
                                                                                <label className="text-sm font-medium mt-5 text-gray-700">
                                                                                    <span>November Section 1</span>
                                                                                </label>
                                                                                <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={startDateNov1}
                                                                                    endDate={endDateNov1}
                                                                                    onChange={(update) => {
                                                                                        setDateRangeNov1(update);
                                                                                    }}
                                                                                    dateFormat={"d MMMM yyyy"}
                                                                                    isClearable={true}
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <label className="text-sm font-medium mt-5 text-gray-700">
                                                                                    <span>November Section 2</span>
                                                                                </label>
                                                                                <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={startDateNov2}
                                                                                    endDate={endDateNov2}
                                                                                    onChange={(update) => {
                                                                                        setDateRangeNov2(update);
                                                                                    }}
                                                                                    dateFormat={"d MMMM yyyy"}
                                                                                    isClearable={true}
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                                                />
                                                                            </div>

                                                                            <div className="mt-4">
                                                                                <label className="text-sm font-medium mt-5 text-gray-700">
                                                                                    <span>December Section 1</span>
                                                                                </label>
                                                                                <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={startDateDec1}
                                                                                    endDate={endDateDec1}
                                                                                    onChange={(update) => {
                                                                                        setDateRangeDec1(update);
                                                                                    }}
                                                                                    dateFormat={"d MMMM yyyy"}
                                                                                    isClearable={true}
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <label className="text-sm font-medium mt-5 text-gray-700">
                                                                                    <span>December Section 2</span>
                                                                                </label>
                                                                                <DatePicker
                                                                                    selectsRange={true}
                                                                                    startDate={startDateDec2}
                                                                                    endDate={endDateDec2}
                                                                                    onChange={(update) => {
                                                                                        setDateRangeDec2(update);
                                                                                    }}
                                                                                    dateFormat={"d MMMM yyyy"}
                                                                                    isClearable={true}
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                                <br />
                                                                <hr />
                                                                <label className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                    <span>Blocked Dates</span>
                                                                </label>
                                                                <button
                                                                    type="button"
                                                                    className="bg-primary w-full text-white py-1 rounded"
                                                                    onClick={() => {
                                                                        values.blocked_dates.push({ date: "" });
                                                                        setValues(values);
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    ADD{" "}
                                                                </button>
                                                                <FieldArray
                                                                    name="blocked_dates"
                                                                    render={(arrayHelpers) => (
                                                                        <div>
                                                                            {values.blocked_dates &&
                                                                            values.blocked_dates.length > 0
                                                                                ? values.blocked_dates.map(
                                                                                    (friend, index) => (
                                                                                        <div
                                                                                            key={index}
                                                                                            className="flex my-2"
                                                                                        >
                                                                                            <Field
                                                                                                name={`blocked_dates.${index}.blocked_date`}
                                                                                                className="rounded-lg block w-60 font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary mr-2"
                                                                                                type="date"
                                                                                                placeholder="Name"
                                                                                            />

                                                                                            <button
                                                                                                type="button"
                                                                                                className="bg-dark_background text-white py-2 px-5 rounded"
                                                                                                onClick={() =>
                                                                                                    arrayHelpers.remove(index)
                                                                                                }
                                                                                            >
                                                                                                {" "}
                                                                                                REMOVE{" "}
                                                                                            </button>
                                                                                        </div>
                                                                                    )
                                                                                )
                                                                                : ""}
                                                                            <div></div>
                                                                        </div>
                                                                    )}
                                                                />
                                                                <br />
                                                                <br />
                                                                {/* <label className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                    <span>
                                        Want to add time before or after your events?
                                    </span>
                                </label>
                                <input type="checkbox" name="before_appointment" checked={beforeTime} onChange={(v) => {setBeforeTime(v.target.checked); setFieldValue("before_appointment", v.target.checked === true ? 1 : 0)}}/> Before Appointment
                                    {beforeTime === true ? 
                                        <>
                                            <select
                                                name="time_before_appointment"
                                                onChange={(e) => setFieldValue("time_before_appointment", e.target.value)}
                                                className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2 w-full"
                                            >
                                                <option value="1" selected={values.time_before_appointment === 1 ? true : false}>5 min</option>
                                                <option value="2" selected={values.time_before_appointment === 2 ? true : false}>10 min</option>
                                                <option value="3" selected={values.time_before_appointment === 3 ? true : false}>15 min</option>
                                                <option value="4" selected={values.time_before_appointment === 4 ? true : false}>20 min</option>
                                                <option value="5" selected={values.time_before_appointment === 5 ? true : false}>25 min</option>
                                                <option value="5" selected={values.time_before_appointment === 6 ? true : false}>30 min</option>
                                                <option value="5" selected={values.time_before_appointment === 7 ? true : false}>35 min</option>
                                                <option value="5" selected={values.time_before_appointment === 8 ? true : false}>40 min</option>

                                            </select>
                                        </> 
                                    : "" }
                                <br/>

                                <input type="checkbox" name="after_appointment" onChange={(v) => {setAfterTime(v.target.checked); setFieldValue("after_appointment", v.target.checked === true ? 1 : 0)}}/> After Appointment
                                    {afterTime === true ? 
                                        <>
                                            <select
                                                name="time_after_appointment"
                                                onChange={(e) => setFieldValue("time_after_appointment", e.target.value)}
                                                className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2 w-full"
                                            >
                                                <option value="1" selected={values.time_after_appointment === 1 ? true : false}>5 min</option>
                                                <option value="2" selected={values.time_after_appointment === 2 ? true : false}>10 min</option>
                                                <option value="3" selected={values.time_after_appointment === 3 ? true : false}>15 min</option>
                                                <option value="4" selected={values.time_after_appointment === 4 ? true : false}>20 min</option>
                                                <option value="5" selected={values.time_after_appointment === 5 ? true : false}>25 min</option>
                                                <option value="6" selected={values.time_after_appointment === 6 ? true : false}>30 min</option>
                                                <option value="7" selected={values.time_after_appointment === 7 ? true : false}>35 min</option>
                                                <option value="8" selected={values.time_after_appointment === 8 ? true : false}>40 min</option>

                                            </select>
                                        </> 
                                    : "" }
                                <br/> */}
                                                                <div className="flex flex-shrink-0 space-x-4 justify-end px-4 py-4">
                                                                    <CustomButton
                                                                        type="button"
                                                                        onClick={() => dispatch(closeModal())}
                                                                        text="Cancel"
                                                                    />
                                                                    <SubmitButton
                                                                        name="Submit"
                                                                        disabled={
                                                                            !isValid || isSubmitting || !dirty
                                                                        }
                                                                        text={button}
                                                                    />
                                                                </div>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </ErrorBoundary>
            </Dialog>
        </Transition.Root>
    );
}
