import React, { useEffect, Fragment, useState } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";
import { Formik, Form } from "formik";
import SettingsSelect from "../Forms/SettingsSelect";
import CustomButton from "../Helpers/CustomButton";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { SvgLoader, SvgProxy } from "react-svgmt";
import { getBooking } from "../../store/booking/bookingActions";

export default function ModalBedAssignment(props) {
  const dispatch = useDispatch();
  const [open] = useState(true);
  const [map, setMap] = useState(
    props.current_booking?.floor_id > 0 ? props.current_booking?.floor_id : ""
  );
  const [unitTypes, setUnittypes] = useState([]);
  const [floors, setFloors] = useState([]);
  const [chosenProperty, setChosenProperty] = useState(
    props.current_booking.property_id > 0
      ? props.current_booking.property_id
      : 0
  );
  const { properties } = useSelector((state) => state.properties);
  const { settings } = useSelector((state) => state.settings);
  const [blocked, setBlocked] = useState("");
  const [moved_in_blocked, setMoved_in_blocked] = useState("");
  const [chosenBed, setChosenBed] = useState(0);
  const { current_staff } = useSelector((state) => state.auth);
  const [currentBed, setCurrentBed] = useState(0);
  const [reservedClick, setReservedClick] = useState(false);
  const [bedLoading, setBedLoading] = useState(false);

  const [bookingsActive, setBookingsActive] = useState([]);
  const [waitlist, setWaitlist] = useState([]);
  const [bedName, setBedName] = useState([]);
  const [oldBedName, setOldBedName] = useState([]);
  const [oldWaitBedName, setOldWaitBedName] = useState([]);
  const [booked, setBooked] = useState("");
  const [waitlisted, setWaitlisted] = useState("");
  const [loading_1, setLoading_1] = useState(false);
  const [loading_3, setLoading_3] = useState(false);
  const [loading_4, setLoading_4] = useState(false);
  const [loading_5, setLoading_5] = useState(false);

  const genders = [
    { label: "Choose an option", value: 0 },
    { label: "Male", value: 1 },
    { label: "Female", value: 2 },
  ];

  useEffect(async () => {
    if (chosenProperty > 0) {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}get_property_floors/${chosenProperty}/${props.current_booking?.user?.profile?.gender}`,
      })
        .then((response) => {
          setFloors(response.data.floors);
          setUnittypes(response.data.unit_types);
        })
        .catch((error) => {
          alert("properties failed");
        });
    }

    if (map > 0) {
      setLoading_1(false);
      setLoading_3(false);
      setLoading_4(false);
      setLoading_5(false);

      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}get_floor_bed_bookings/${map}/1/${props.current_booking?.year_id}/0`,
      })
        .then((response) => {
          let var_blocked = ".blocked";
          if (response.data !== "") {
            var_blocked += "," + response.data;
          }

          setBlocked(var_blocked);
        })
        .catch((error) => {
          alert("properties failed");
        })
        .finally(() => {
          setLoading_1(true);
        });

      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}get_floor_bed_bookings/${map}/5/${props.current_booking?.year_id}/0`,
      })
        .then((response) => {
          let var_blocked = ".movedIn";
          if (response.data !== "") {
            var_blocked += "," + response.data;
          }

          setMoved_in_blocked(var_blocked);
        })
        .catch((error) => {
          alert("properties failed");
        })
        .finally(() => {
          setLoading_5(true);
        });

      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}get_floor_bed_bookings/${map}/3/${props.current_booking?.year_id}/0`,
      })
        .then((response) => {
          setBooked(response.data);
        })
        .catch((error) => {
          alert("properties failed");
        })
        .finally(() => {
          setLoading_3(true);
        });

      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}get_floor_bed_bookings/${map}/4/${props.current_booking?.year_id}/0`,
      })
        .then((response) => {
          setWaitlisted(response.data);
        })
        .catch((error) => {
          alert("properties failed");
        })
        .finally(() => {
          setLoading_4(true);
        });
    }

    setCurrentBed(
      props.current_booking.bed_id
        ? props.current_booking.bed_id
        : props.current_booking.waiting_bed_id
    );
  }, [chosenProperty, map]);

  async function getBedInfo(id) {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}get_bed_info/${id}`,
    })
      .then((response) => {
        setBedName(response.data);
      })
      .catch((error) => {
        alert("bed load failed");
      });

    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}get_bed_info/${id}`,
    })
      .then((response) => {
        setOldBedName(response.data);
      })
      .catch((error) => {
        alert("bed load failed");
      });

    if (props.current_booking.waiting_bed_id > 0) {
      await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}get_bed_info/${props.current_booking.waiting_bed_id}`,
      })
        .then((response) => {
          setOldWaitBedName(response.data);
        })
        .catch((error) => {
          alert("bed load failed");
        });
    }

    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}get_bed_bookings/${id}/2/${props.current_booking?.year_id}`,
    })
      .then((response) => {
        setBookingsActive(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}get_bed_bookings/${id}/3/${props.current_booking?.year_id}`,
    })
      .then((response) => {
        setWaitlist(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      border:
        state.isFocused && "none" ? "1px solid #9F7D38" : "1px solid #d1d5db",
      cursor: "pointer",
      "&:hover": {
        border: state.isFocused ? "1px solid #9F7D38" : "1px solid #d1d5db",
        cursor: "pointer",
      },
      borderRadius: "10px",
      padding: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      padding: 0,
      border: 0,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#e7ebe5",
    }),
  };

  async function set_new_bed(bed_id) {
    if (
      current_staff.isSuper == 1 ||
      current_staff.email == "rowan@qholdings.co.za"
    ) {
      setBedLoading(true);
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}change_user_bed`,
        data: {
          booking_id: props.current_booking.id,
          year: props.current_booking.year_id,
          bed_id: parseInt(bed_id),
          user: current_staff,
          type: props.type,
          tag_id: props.tag_id,
        },
      })
        .then((response) => {
          dispatch(getBooking(props.current_booking.id));
          setChosenBed(0);
          dispatch(closeModal());
        })
        .catch((error) => {
          alert("properties failed");
        });
      setBedLoading(false);
    } else {
      alert("Access Denised");
    }
  }

  function set_new_waiting_bed(waiting_bed_id) {
    if (
      current_staff.isSuper == 1 ||
      current_staff.email == "rowan@qholdings.co.za"
    ) {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}change_user_waiting_bed`,
        data: {
          booking_id: props.current_booking.id,
          waiting_bed_id: parseInt(waiting_bed_id),
          user: current_staff,
        },
      })
        .then((response) => {
          dispatch(getBooking(props.current_booking.id));
          setChosenBed(0);
          dispatch(closeModal());
        })
        .catch((error) => {
          console.log(error);
          alert("properties failed");
        });
    } else {
      alert("Access Denised");
    }
  }

  async function set_new_primary_bed(primary_bed_id) {
    if (
      current_staff.isSuper == 1 ||
      current_staff.email == "rowan@qholdings.co.za"
    ) {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}change_user_primary_bed`,
        data: {
          booking_id: props.current_booking.id,
          primary_bed_id: parseInt(primary_bed_id),
          user: current_staff,
        },
      })
        .then((response) => {
          dispatch(getBooking(props.current_booking.id));
          setChosenBed(0);
          dispatch(closeModal());
        })
        .catch((error) => {
          console.log(error);
          alert("properties failed");
        });
    } else {
      alert("Access Denised");
    }
  }

  function delinkBed() {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}delink_user_bed`,
      data: {
        booking_id: props.current_booking.id,
        user: current_staff,
        move_location: 0,
        type: props.type,
        tag_id: props.tag_id,
      },
    })
      .then((response) => {
        dispatch(getBooking(props.current_booking.id));
        setChosenBed(0);
        dispatch(closeModal());
      })
      .catch((error) => {
        alert("properties failed");
      });
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <ErrorBoundary>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <CustomButton
                    type="secondary"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    Close
                  </CustomButton>
                </div>

                <div className="w-full">
                  <div className="flex justify-between mb-4 items-center">
                    <div>
                      <h2 className="font-bold text-lg">
                        {props.current_booking.user.name}{" "}
                        {props.current_booking.user.surname}
                      </h2>
                    </div>

                    {(current_staff.isSuper == 1 ||
                      current_staff.email == "rowan@qholdings.co.za") &&
                      props.current_booking?.bed_id > 0 && (
                        <button
                          className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                          onClick={() => delinkBed()}
                        >
                          DE-LINK BED
                        </button>
                      )}

                    <button
                      className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                      onClick={() => dispatch(closeModal())}
                    >
                      CANCEL
                    </button>
                  </div>

                  {/* {chosenBed > 0 ? ( */}
                  {chosenBed > 0 && !reservedClick ? (
                    <div>
                      {props.current_booking.waiting_bed_id > 0 && (
                        <div>
                          <span>
                            This will remove the user from the waiting list for
                            bed
                          </span>{" "}
                          {/* {props.current_booking.waiting_bed_id} */}
                          {oldWaitBedName && oldWaitBedName.name ? (
                            <>
                              {oldWaitBedName.property.name}{" "}
                              {oldWaitBedName.unit.name} {oldWaitBedName.name}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                      <div>
                        Change to bed{" "}
                        {bedName && bedName.name ? (
                          <>
                            {bedName.property.name} {bedName.unit.name}{" "}
                            {bedName.name}?
                          </>
                        ) : (
                          ""
                        )}
                        {/* {chosenBed}*/}
                      </div>

                      <div className="space-x-2 mt-4">
                        <button
                          className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                          onClick={() => set_new_bed(chosenBed)}
                        >
                          {bedLoading === true ? (
                            <>
                              <FontAwesomeIcon
                                className="w-4 h-4 animate-spin"
                                icon={faLoader}
                              />
                              <span className="w-full">
                                {props.loadingtext
                                  ? props.loadingtext
                                  : "Loading"}
                              </span>
                            </>
                          ) : (
                            "Yes"
                          )}
                        </button>
                        <button
                          className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                          onClick={() => setChosenBed(0)}
                        >
                          No
                        </button>

                        {props.current_booking.waiting_bed_id ===
                        parseFloat(chosenBed) ? (
                          <>
                            <button
                              className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                              onClick={() => set_new_primary_bed(chosenBed)}
                            >
                              Make Primary
                            </button>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : chosenBed > 0 && reservedClick ? (
                    // props.current_booking.waiting_bed_id > 0 && (
                    <div>
                      {props.current_booking.waiting_bed_id > 0 && (
                        <div>
                          <span>
                            This will remove the user from the waiting list for
                            bed
                          </span>{" "}
                          {/* {props.current_booking.waiting_bed_id} */}
                          {oldWaitBedName && oldWaitBedName.name ? (
                            <>
                              {oldWaitBedName.property.name}{" "}
                              {oldWaitBedName.unit.name} {oldWaitBedName.name}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                      {props.current_booking.bed_id > 0 && (
                        <div>
                          <span>This will remove the user from bed</span>{" "}
                          {props.current_booking.bed_id}{" "}
                          <span>and add them to the waiting list for</span>{" "}
                          {chosenBed}
                        </div>
                      )}
                      {/* <div>Change to waitlist for bed {chosenBed}?</div> */}

                      <div>
                        Change to waitlist for bed{" "}
                        {bedName && bedName.name ? (
                          <>
                            {bedName.property.name} {bedName.unit.name}{" "}
                            {bedName.name}?
                          </>
                        ) : (
                          ""
                        )}
                        {/* {chosenBed}*/}
                      </div>

                      <div className="space-x-2 mt-4">
                        <button
                          className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                          onClick={() => set_new_waiting_bed(chosenBed)}
                        >
                          Yes
                        </button>
                        <button
                          className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                          onClick={() => setChosenBed(0)}
                        >
                          No
                        </button>

                        {props.current_booking.waiting_bed_id ===
                        parseFloat(chosenBed) ? (
                          <>
                            <button
                              className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                              onClick={() => set_new_primary_bed(chosenBed)}
                            >
                              Make Primary
                            </button>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    <Formik
                      initialValues={{
                        property_id: props.current_booking.property_id,
                        room_type: props.current_booking?.unit_type?.id
                          ? props.current_booking?.unit_type?.id
                          : props.current_booking?.unit_type
                          ? props.current_booking?.unit_type
                          : "",
                        floor_id: props.current_booking.floor_id,
                      }}
                      onSubmit={async (
                        values,
                        { setSubmitting, setErrors, resetForm }
                      ) => {
                        setMap(values.floor_id);
                      }}
                    >
                      {({
                        values,
                        touched,
                        errors,
                        setFieldValue,
                        setFieldTouched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        dirty,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <div className="colour-uc6 rounded-3xl py-7 px-20 flex justify-between items-center mb-4">
                            <div className="flex w-4/5 flex-wrap">
                              <div className="w-1/3 px-3 z-20">
                                <div>
                                  <label
                                    htmlFor="unit_id"
                                    className="text-sm font-medium text-gray-700"
                                  >
                                    Property
                                  </label>
                                  <Select
                                    id="property_id"
                                    name="property_id"
                                    options={properties}
                                    onChange={(val) => {
                                      setFieldValue("property_id", val.value);
                                      setChosenProperty(val.value);

                                      setFieldValue("floor_id", 0);
                                      setFieldValue("room_type", 0);
                                    }}
                                    onBlur={setFieldTouched}
                                    value={
                                      properties && properties.length > 0
                                        ? properties.find(
                                            (option) =>
                                              option.value ===
                                              values.property_id
                                          )
                                        : ""
                                    }
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                    styles={customStyles}
                                    className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                  />
                                  <p className="text-red-400 text-sm">
                                    {errors.property_id}
                                  </p>
                                </div>
                              </div>

                              <div className="w-1/3 px-3 z-20">
                                <SettingsSelect
                                  label="ROOM TYPE"
                                  name="room_type"
                                  required
                                  onChange={setFieldValue}
                                  onBlur={setFieldTouched}
                                  value={values.room_type}
                                  touched={touched.room_type}
                                  error={errors.room_type}
                                  options={unitTypes}
                                />
                              </div>

                              <div className="w-1/3 px-3 z-20">
                                <SettingsSelect
                                  label="FLOOR"
                                  name="floor_id"
                                  required
                                  onChange={setFieldValue}
                                  onBlur={setFieldTouched}
                                  value={values.floor_id}
                                  touched={touched.floor_id}
                                  error={errors.floor_id}
                                  options={floors}
                                />
                              </div>
                              {props.current_booking.user?.profile?.gender >
                                0 &&
                                props.current_booking.unit?.floor?.id > 0 &&
                                props.current_booking.unit?.floor?.gender !=
                                  props.current_booking.user?.profile
                                    ?.gender && (
                                  <>
                                    <h3 className="text-red-700 font-bold">
                                      This user is currently assigned to a bed
                                      that doesn't match their gender.
                                    </h3>
                                    <p>
                                      To reassign them to a matching floor, use
                                      the search button to find them a suitable
                                      bed.
                                    </p>
                                  </>
                                )}
                            </div>

                            <div>
                              <button
                                type="submit"
                                className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                              >
                                SEARCH
                              </button>
                            </div>
                            <div>
                              {props.current_booking.waiting_bed_id > 0 && (
                                <h2 className="">
                                  This user is on a waitlist for the purple bed
                                  (or similar).
                                </h2>
                              )}
                            </div>
                          </div>

                          {map !== "" &&
                            blocked !== "" &&
                            moved_in_blocked !== "" && (
                              <>
                                <div className="flex justify-center items-center space-x-12">
                                  {process.env.REACT_APP_COLOUR === "UC" ? (
                                    <div className="flex space-x-2 justify-center items-center">
                                      <div
                                        className="w-5 h-5 rounded-full"
                                        style={{ backgroundColor: "black" }}
                                      ></div>
                                      <div className="font-bold">
                                        {loading_1 === false
                                          ? "Loading..."
                                          : "Reserved / Blocked"}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="flex space-x-2 justify-center items-center">
                                      <div
                                        className="w-5 h-5 rounded-full"
                                        style={{ backgroundColor: "#c9c9c9" }}
                                      ></div>
                                      <div className="font-bold">Occupied</div>
                                    </div>
                                  )}

                                  <div className="flex space-x-2 justify-center items-center">
                                    <div
                                      className="w-5 h-5 rounded-full"
                                      style={{ backgroundColor: "blue" }}
                                    ></div>
                                    <div className="font-bold">
                                      {process.env.REACT_APP_COLOUR === "UC"
                                        ? loading_3 === false
                                          ? "Loading..."
                                          : "New Leads"
                                        : "Last month of lease"}
                                    </div>
                                  </div>

                                  <div className="flex space-x-2 justify-center items-center">
                                    <div
                                      className="w-5 h-5 rounded-full"
                                      style={{ backgroundColor: "purple" }}
                                    ></div>
                                    <div className="font-bold">
                                      {process.env.REACT_APP_COLOUR === "UC"
                                        ? loading_4 === false
                                          ? "Loading..."
                                          : "Renewal"
                                        : "2nd last month of lease"}
                                    </div>
                                  </div>

                                  <div className="flex space-x-2 justify-center items-center">
                                    <div
                                      className="w-5 h-5 rounded-full"
                                      style={{ backgroundColor: "deeppink" }}
                                    ></div>
                                    <div className="font-bold">
                                      {process.env.REACT_APP_COLOUR === "UC"
                                        ? loading_5 === false
                                          ? "Loading..."
                                          : "Moved In"
                                        : "Overlapping Leases"}
                                    </div>
                                  </div>

                                  <div className="flex space-x-2 justify-center items-center">
                                    <div
                                      className="w-5 h-5 rounded-full"
                                      style={{ backgroundColor: "green" }}
                                    ></div>
                                    <div className="font-bold">
                                      {process.env.REACT_APP_COLOUR === "UC"
                                        ? "Mapped"
                                        : "Vacant"}
                                    </div>
                                  </div>

                                  {process.env.REACT_APP_COLOUR === "QL" ? (
                                    <div className="flex space-x-2 justify-center items-center">
                                      <div
                                        className="w-5 h-5 rounded-full"
                                        style={{ backgroundColor: "#757575" }}
                                      ></div>
                                      <div className="font-bold">
                                        Lease expired M2M
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  {process.env.REACT_APP_COLOUR === "UC" ? (
                                    <div className="flex space-x-2 justify-center items-center">
                                      <div
                                        className="w-5 h-5 rounded-full"
                                        style={{ backgroundColor: "#e5e7eb" }}
                                      ></div>
                                      <div className="font-bold">Unmapped</div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div
                                  style={{ height: "90vh" }}
                                  className="frontend_svg"
                                >
                                  <SvgLoader
                                    path={
                                      process.env.REACT_APP_LARAVEL_URL +
                                      "api/get_svg/" +
                                      map
                                    }
                                  >
                                    <SvgProxy
                                      selector=".mapped"
                                      fill="rgb(227,227,227)"
                                      onClick={(e) => {
                                        if (
                                          current_staff.isSuper == 1 ||
                                          current_staff.email ==
                                            "rowan@qholdings.co.za" ||
                                          current_staff.email ==
                                            "Phumzile@urbancircle.co.za"
                                        ) {
                                          setChosenBed(
                                            e.currentTarget.getAttribute(
                                              "bedid"
                                            )
                                          );
                                          setReservedClick(false);
                                          getBedInfo(
                                            e.currentTarget.getAttribute(
                                              "bedid"
                                            )
                                          );
                                        } else {
                                          alert("Access denied");
                                        }
                                      }}
                                    />
                                    <SvgProxy
                                      selector=".room > g > rect.room_inner.highlighted, .room > g > path.room_inner.highlighted, .room
                                        > g > rect.room_container.highlighted, .room > g > path.room_container.highlighted"
                                      fill="#2ceaeb"
                                    />
                                    <SvgProxy
                                      selector=".taken"
                                      fill="#ff0000"
                                    />
                                    <SvgProxy
                                      selector=".resident_reserved"
                                      fill="black"
                                    />

                                    {booked != "" && (
                                      <SvgProxy
                                        selector={booked}
                                        fill="blue"
                                        onClick={() =>
                                          alert(
                                            "A new lead has this bed already!"
                                          )
                                        }
                                      />
                                    )}

                                    {waitlisted != "" && (
                                      <SvgProxy
                                        selector={waitlisted}
                                        fill="purple"
                                        onClick={() =>
                                          alert(
                                            "A renewal has this bed already!"
                                          )
                                        }
                                      />
                                    )}

                                    <SvgProxy
                                      selector={blocked}
                                      fill="red"
                                      // onClick={() => alert("blocked")}
                                      onClick={(e) => {
                                        if (
                                          current_staff.isSuper == 1 ||
                                          current_staff.email ==
                                            "rowan@qholdings.co.za" ||
                                          current_staff.email ==
                                            "Phumzile@urbancircle.co.za"
                                        ) {
                                          setChosenBed(
                                            e.currentTarget.getAttribute(
                                              "bedid"
                                            )
                                          );
                                          setReservedClick(true);
                                          getBedInfo(
                                            e.currentTarget.getAttribute(
                                              "bedid"
                                            )
                                          );
                                        } else {
                                          alert("Access denied");
                                        }
                                      }}
                                    />

                                    <SvgProxy
                                      selector=".blocked.yellow"
                                      fill="yellow"
                                      onClick={() => alert("blocked")}
                                    />

                                    <SvgProxy
                                      selector={moved_in_blocked}
                                      fill="deeppink"
                                      onClick={() => alert("blocked")}
                                    />

                                    <SvgProxy
                                      selector={
                                        "#bed_" + props.current_booking?.bed?.id
                                      }
                                      fill="green"
                                    />

                                    <SvgProxy
                                      selector={
                                        "#bed_" +
                                        props.current_booking.waiting_bed_id
                                      }
                                      fill="purple"
                                    />
                                  </SvgLoader>
                                </div>
                              </>
                            )}
                        </Form>
                      )}
                    </Formik>
                  )}

                  {chosenBed &&
                  chosenBed > 0 &&
                  (bookingsActive.length > 0 || waitlist.length > 0) ? (
                    <>
                      <h2 className="bg-gray-200 mt-5 p-5 text-lg font-medium border-indigo-600 border-x-2 border-t-2">
                        Current Bed Bookings
                      </h2>
                      <div className="flex bg-gray-200 px-5 border-indigo-600 border-x-2 border-b-2 mb-2">
                        <div className="w-1/2">
                          <h2 className="text-lg font-medium text-primary mb-4">
                            Booking History
                          </h2>

                          {bookingsActive?.map((b) => (
                            <div className="mb-4">
                              <div key={b.id}>
                                {b.booking_reference} {b.user?.name}
                                <br />
                                Paid:{" "}
                                {b.rates?.length > 0
                                  ? b.rates[b.rates.length - 1].paid === 1
                                    ? "Yes"
                                    : "No"
                                  : "No"}
                                <br />
                                Lease Signed:{" "}
                                {b.rates?.length > 0
                                  ? b.rates[b.rates.length - 1]
                                      .signed_lease_at !== ""
                                    ? b.rates[b.rates.length - 1]
                                        .signed_lease_at
                                    : "No"
                                  : "No"}
                                <br />
                              </div>
                            </div>
                          ))}
                        </div>

                        <div className="w-1/2">
                          <h2 className="text-lg font-medium text-primary mb-4">
                            Waiting List
                          </h2>
                          {waitlist?.map((b) => (
                            <div className="mb-4">
                              <div key={b.id}>
                                {b.booking_reference ===
                                props.current_booking.booking_reference ? (
                                  <>
                                    <span className="text-green-600 font-bold">
                                      {b.booking_reference} {b.user?.name}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    {b.booking_reference} {b.user?.name}
                                  </>
                                )}
                                <br />
                                Paid:{" "}
                                {b.rates?.length > 0
                                  ? b.rates[b.rates.length - 1].paid === 1
                                    ? "Yes"
                                    : "No"
                                  : "No"}
                                <br />
                                Lease Signed:{" "}
                                {b.rates?.length > 0
                                  ? b.rates[b.rates.length - 1]
                                      .signed_lease_at !== ""
                                    ? b.rates[b.rates.length - 1]
                                        .signed_lease_at
                                    : "No"
                                  : "No"}
                                <br />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </ErrorBoundary>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
