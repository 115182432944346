import React, { Fragment, useEffect, useState } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";

import NotificationToast from "../Helpers/NotificationToast";
import CustomButton from "../Helpers/CustomButton";
import {
	faTimes,
	faClock,
	faLocationDot,
	faLoader,
} from "@fortawesome/pro-regular-svg-icons";
import { format, intervalToDuration } from "date-fns";
import { Link } from "react-router-dom";
import { getBooking } from "../../store/booking/bookingActions";
import AppointmentFileUpload from "../Forms/AppointmentFileUpload";
import AppointmentFileDisplay from "../Forms/AppointmentFileDisplay";

export default function ModalViewAppointment(props) {
	const [loading, setLoading] = useState(true);
	const [appointmentMain, setAppointmentMain] = useState([]);
	const [appointmentDoc, setAppointmentDoc] = useState([]);
	const [sendList, setSendList] = useState([]);
	const [resendLoading, setResendLoading] = useState(false);
	const [approveLoading, setApproveLoading] = useState(false);
	const dispatch = useDispatch();
	const [open] = useState(true);
	const { current_staff } = useSelector((state) => state.auth);

	const [loadAppointment, setLoadAppointment] = useState(true);

	useEffect(() => {
		if (loadAppointment === true) {
			setLoadAppointment(false);
			axios({
				method: "post",
				url: `${process.env.REACT_APP_API_URL}view_appointment`,
				data: { appointment_id: props.booking_id },
			})
				.then((response) => {
					setAppointmentDoc(response.data[0]);
					setAppointmentMain(response.data[1]);
					setSendList(response.data[2]);
					setLoading(false);
				})
				.catch(() => {
					setAppointmentDoc([]);
					setAppointmentMain([]);
					setLoading(false);
				});
		}
	}, [loadAppointment]);

	async function resendBmApproval(appointmentId) {
		setResendLoading(true);

		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}resend_appointment`,
			data: { appointmentId: appointmentId },
		})
			.then((response) => {
				toast(
					<NotificationToast
						title={"Sent successfully"}
						message={"Your message has been sent."}
					/>
				);
			})
			.catch(() => {
				toast(
					<NotificationToast
						title="Something went wrong"
						message={"Couldn't send email"}
						type="error"
					/>,
					{
						autoClose: false,
					}
				);
			});

		setResendLoading(false);
	}

	async function approveRequest(appointmentId) {
		setApproveLoading(true);

		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}approve_appointment`,
			data: { appointment_id: appointmentId, manager_id: current_staff.id },
		})
			.then((response) => {
				toast(
					<NotificationToast
						title={"Approved"}
						message={"Your appointment has been approved."}
					/>
				);
				props.getBookings(props.property_id);
				dispatch(closeModal());
			})
			.catch(() => {
				toast(
					<NotificationToast
						title="Something went wrong"
						message={"Couldn't approve appointment"}
						type="error"
					/>,
					{
						autoClose: false,
					}
				);
			});

		setApproveLoading(false);
	}

	async function cancelRequest(appointmentId, propertyId) {
		setResendLoading(true);

		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}cancel_request`,
			data: { appointment_id: appointmentId },
		})
			.then((response) => {
				toast(
					<NotificationToast
						title={"Cancelled successfully"}
						message={"Your appointment has been cancelled."}
					/>
				);
			})
			.catch(() => {
				toast(
					<NotificationToast
						title="Something went wrong"
						message={"Couldn't cancel appointment"}
						type="error"
					/>,
					{
						autoClose: false,
					}
				);
			});

		// props.setNewBookingProp(propertyId);
		// props.setNewBookings(1);
		setResendLoading(false);
		dispatch(closeModal());
	}

	async function cancelAppointment(appointmentId, propertyId) {
		setResendLoading(true);

		await axios({
			method: "post",
			url: `${process.env.REACT_APP_API_URL}cancel_appointment`,
			data: { appointment_id: appointmentId },
		})
			.then((response) => {
				toast(
					<NotificationToast
						title={"Cancelled successfully"}
						message={"Your message has been sent."}
					/>
				);
			})
			.catch(() => {
				toast(
					<NotificationToast
						title="Something went wrong"
						message={"Couldn't send email"}
						type="error"
					/>,
					{
						autoClose: false,
					}
				);
			});

		// props.setNewBookingProp(propertyId);
		// props.setNewBookings(1);
		setResendLoading(false);
		dispatch(closeModal());
	}

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				static
				className="fixed z-50 inset-0 overflow-y-auto"
				open={open}
				onClose={() => dispatch(closeModal())}
			>
				<ErrorBoundary>
					<div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="hidden sm:inline-block sm:align-middle sm:h-screen"
							aria-hidden="true"
						>
              &#8203;
            </span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
								<div className="block absolute top-0 right-0 pt-4 pr-4">
									<button
										type="button"
										className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
										onClick={() => dispatch(closeModal())}
									>
										<span className="sr-only">Close</span>
										<FontAwesomeIcon
											className="h-6 w-6"
											aria-hidden="true"
											icon={faTimes}
										/>
									</button>
								</div>
								{loading === false ? (
									<>
										<h1 className="text-xl">
											{appointmentMain?.name !== null
												? appointmentMain?.name
												: "BM Meeting"}
										</h1>
										<p className="font-semibold ">
											{appointmentDoc?.property
												? (appointmentDoc?.property?.name).split(" - ")[0]
												: ""}
										</p>
										<br />
										<p className="font-semibold ">
											<FontAwesomeIcon icon={faClock} />{" "}
											{appointmentMain !== null ? (
												<>
													{appointmentMain.duration === 1
														? "15 min"
														: appointmentMain.duration === 2
															? "30 min"
															: appointmentMain.duration === 3
																? "45 min"
																: appointmentMain.duration === 4
																	? "1 hour"
																	: appointmentMain.duration === 5
																		? " 1 hour 15 min"
																		: appointmentMain.duration === 6
																			? "1 hour 30 min"
																			: appointmentMain.duration === 7
																				? "1 hour 45 min"
																				: appointmentMain.duration === 8
																					? "2 hours"
																					: ""}
												</>
											) : (
												<>
													{
														intervalToDuration({
															start: new Date(
																appointmentDoc.booked_date +
																" " +
																appointmentDoc.booked_time
															),
															end: new Date(
																appointmentDoc.booked_date +
																" " +
																appointmentDoc.time_to
															),
														}).hours
													}{" "}
													hours{" "}
													{
														intervalToDuration({
															start: new Date(
																appointmentDoc.booked_date +
																" " +
																appointmentDoc.booked_time
															),
															end: new Date(
																appointmentDoc.booked_date +
																" " +
																appointmentDoc.time_to
															),
														}).minutes
													}{" "}
													minutes
												</>
											)}
										</p>
										<p className="font-semibold ">
											<FontAwesomeIcon icon={faLocationDot} />{" "}
											{appointmentDoc?.property
												? appointmentDoc?.property.address
												: ""}
										</p>
										<br />
										<p>
											Appointment at:{" "}
											<span className="font-bold">
												{format(
													new Date(appointmentDoc.booked_date),
													"ccc, dd MMMM yyyy"
												)}
												{appointmentDoc.booked_time && ", "}
												{appointmentDoc.booked_time && format(
													new Date(
														appointmentDoc.booked_date +
														" " +
														appointmentDoc.booked_time
													),
													"HH:mm"
												)}{" "}
												{appointmentDoc.time_to && "- "}
												{appointmentDoc.time_to && format(
													new Date(
														appointmentDoc.booked_date +
														" " +
														appointmentDoc.time_to
													),
													"HH:mm"
												)}
                      						</span>
										</p>
										<br />
										<p>
											Appointment Status:{" "}
											<span className="font-bold">
                        {appointmentDoc.status === 1
							? "Requested"
							: appointmentDoc.status === 2
								? "Confirmed"
								: appointmentDoc.status === 0
									? "Cancelled"
									: "Unknown"}
                      </span>
										</p>
										<p>
											Attending Manager:{" "}
											<Link
												to={{
													pathname: "/calendar/landing",
													query: {
														property:
															appointmentMain?.property_id !== null
																? appointmentMain?.property_id
																: appointmentDoc.property_id,
													},
												}}
												onClick={() => dispatch(closeModal())}
											>
                        <span className="font-bold">
                          {appointmentDoc.bm !== null
							  ? appointmentDoc.manager.name +
							  " " +
							  appointmentDoc.manager.surname +
							  " - " +
							  appointmentDoc.manager.email
							  : ""}
                        </span>
											</Link>
										</p>
										<br />
										{appointmentDoc.name ? (
											<p>
												Name:{" "}
												<span className="font-bold">{appointmentDoc.name}</span>
											</p>
										) : (
											""
										)}
										{appointmentDoc.email ? (
											<p>
												Email:{" "}
												<span className="font-bold">
                          {appointmentDoc.email}
                        </span>
											</p>
										) : (
											""
										)}
										{appointmentDoc.cell ? (
											<p>
												Cell:{" "}
												<span className="font-bold">
                          +{appointmentDoc.cell}
                        </span>
											</p>
										) : (
											""
										)}
										{appointmentDoc.booking_ref ? (
											<>
												<p>
													Booking Reference:
													<Link
														to={
															"/booking/management?tag=21&user_id=" +
															appointmentDoc.booking_ref
																.slice(1)
																.split("-")[0] +
															"&booking_id=" +
															appointmentDoc.booking_ref.split("-")[1]
														}
														onClick={() => {
															dispatch(
																getBooking(
																	appointmentDoc.booking_ref
																		.slice(1)
																		.split("-")[1]
																)
															);
															dispatch(closeModal());
														}}
													>
                            <span className="font-bold">
                              {appointmentDoc.booking_ref}
                            </span>
													</Link>
												</p>
											</>
										) : (
											""
										)}

										{appointmentDoc.description ? (
											<>
												<br />
												<p>Description:</p>
												<p>{appointmentDoc.description}</p>
											</>
										) : (
											""
										)}
										<br />
										{sendList && sendList.length > 0 ? (
											<>
												<p>Sent to:</p>
												{sendList.map((send) => (
													<p>{send}</p>
												))}
											</>
										) : (
											""
										)}

										<br />
										<p>
											<b>Attachments:</b>
										</p>
										<ul>
											{appointmentDoc &&
												appointmentDoc.files.length > 0 &&
												appointmentDoc.files.map((file) => (
													<>
														<li>
															<AppointmentFileDisplay
																file={file}
																key={file.id}
																showFile={true}
																fileId={file.id}
																onScreen={false}
																setLoadAppointment={setLoadAppointment}
															/>
														</li>
													</>
												))}
										</ul>

										<br />
										<p>
											<b>Add Attachments:</b>
										</p>

										<AppointmentFileUpload
											appointment_id={appointmentDoc.id}
											setLoadAppointment={setLoadAppointment}
										/>
										<br />
										<div class="flex">
											{appointmentDoc.send_to !== null &&
											(appointmentDoc.send_to
													.split(",")
													.includes(current_staff.id.toString()) ||
												current_staff.id == 1) &&
											appointmentDoc.status === 1 &&
											(appointmentMain.name === "Plumbing Callout" ||
												appointmentMain.name === "Electrical Callout") ? (
												<>
													<div>
														<CustomButton
															text="Approve Request"
															onClick={() => approveRequest(appointmentDoc.id)}
															loading={approveLoading}
														/>
													</div>
												</>
											) : (
												""
											)}
											{appointmentMain !== null &&
											(appointmentMain.name === "Plumbing Callout" ||
												appointmentMain.name === "Electrical Callout") ? (
												<>
													<div className="flex flex-row-reverse flex-grow">
														<CustomButton
															text="Cancel Request"
															onClick={() =>
																cancelRequest(
																	appointmentDoc.id,
																	props.property_id
																)
															}
															loading={resendLoading}
														/>
													</div>
												</>
											) : (
												""
											)}

											{appointmentMain !== null &&
											appointmentMain.name !== "Plumbing Callout" &&
											appointmentMain.name !== "Electrical Callout" ? (
												<>
													<div className="">
														<CustomButton
															text="Resend Approval Request"
															onClick={() =>
																resendBmApproval(appointmentDoc.id)
															}
															loading={resendLoading}
														/>
													</div>
													<div className="flex flex-row-reverse flex-grow">
														<CustomButton
															text="Cancel Request"
															onClick={() =>
																cancelAppointment(
																	appointmentDoc.id,
																	appointmentMain?.property.id
																)
															}
															loading={resendLoading}
														/>
													</div>
												</>
											) : (
												""
											)}
										</div>
									</>
								) : (
									<FontAwesomeIcon
										className="h-10 w-10 text-gray-900 animate-spin ml-52"
										icon={faLoader}
									/>
								)}
							</div>
						</Transition.Child>
					</div>
				</ErrorBoundary>
			</Dialog>
		</Transition.Root>
	);
}
