import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { faNote, faMessageLines, faEnvelope, faBrowser } from "@fortawesome/pro-regular-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import NotificationToast from "../Helpers/NotificationToast";
import BookingTextArea from "./BookingTextArea";
import BookingWysiwyg from "./BookingWysiwyg";
import BookingTemplate from "./BookingTemplate";
import BookingPlatformButton from "./BookingPlatformButton";
import SubmitButton from "../Forms/SubmitButton";
import BookingFileUpload from "../Forms/BookingFileUpload";
import EmojiPicker from "../Forms/EmojiPicker";
import BookingPlaceholder from "./BookingPlaceholder";
import BookingWaBus from "./BookingWaBus";
import BookingWaBank from "./BookingWaBank";

import ProfilePicture from "../Auth/ProfilePicture";
import BookingwaDocs from "./BookingWaDocs";

export default function BookingForm({ setDoc, db, messagesEndRef, abspos, type, tag_id }) {
    const { current_staff, staff } = useSelector((state) => state.auth);
    const { current_booking } = useSelector((state) => state.booking);
    const { appointments } = useSelector((state) => state.appointments);
    const [mentions, setMentions] = useState([]);
    const [allowMention, setAllowMention] = useState(false);

    // const [whisperActive, setWhisperActive] = useState(false);

    let show_web_chat = false;

    if (current_booking?.chat_id > 0) {
        var now = new Date();
        var end = new Date(current_booking?.chat_timestamp);

        var diff = parseInt((Math.abs(end.getTime() - now.getTime()) / (1000 * 60)) % 60);
        var diffdays = Math.ceil(Math.abs(now - end) / (1000 * 60 * 60 * 24));

        // console.log(diffdays);
    }
    if (current_booking.assigned_to !== null && allowMention === false) {
        setAllowMention(true);
        setMentions(current_booking.assigned_to);
    }

    // if(callOnce === false){
    //     setCallOnce(true)
    //     appointments.forEach(function(appointment){
    //         if((appointment.property_id === current_booking.property_id) || ((current_booking.property_id === 7 || current_booking.property_id === 3) && appointment.property_id === 6)){

    //             if(appointment.name === "Move Out Inspection"){
    //                 setCustomMsg(window.location.origin+"/appointment/"+appointment.link_url+"/"+appointment.id+"/0/0/"+current_booking?.user.edit_url)
    //             }
    //         }
    //     })
    // }

    // console.log("current_staff", (window.localStorage.token).split("|")[1])

    // useEffect(() => {

    //     axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    //     const echo = new Echo({
    //         broadcaster: "pusher",
    //         key: process.env.REACT_APP_MIX_ABLY_PUBLIC_KEY,

    //         wsHost: process.env.REACT_APP_MIX_ABLY_URL,
    //         wssHost: process.env.REACT_APP_MIX_ABLY_URL,

    //         wsPort: process.env.REACT_APP_MIX_ABLY_PORT,
    //         wssPort: process.env.REACT_APP_MIX_ABLY_PORT,

    //         disableStats: true,
    //         encrypted: false,
    //         enabledTransports: ["ws", "wss"],
    //         forceTLS: true,

    //         enableLogging: true,
    //         authEndpoint: process.env.REACT_APP_LARAVEL_URL+"broadcasting/auth",
    //         auth: {
    //             headers: {
    //                 Authorization: 'Bearer ' + window.localStorage.token
    //             },
    //         },
    //     });

    //     // console.log("ECHO", echo);

    //     // if(whisperActive === true){
    //         console.log("1")
    //     //     setWhisperActive(false)

    //         echo.private(`Typing.${current_booking.id}`)
    //             .whisper('Typing', {
    //                 name: 'one',
    //                 typing: true
    //             });

    //     // }
    //     // echo.private(`Typing.${current_booking.id}`)
    //     //     .listenForWhisper('Typing', (e) => {
    //     //         console.log(e.name);
    //     //     });

    //     return () => {
    //         echo.disconnect();
    //     };

    // }, [whisperActive])

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    message: "",
                    platform: "",
                    from: current_staff?.id,
                    to: "",
                    files: [],
                    template: 0,
                    temaplte_name: "",
                    booking: current_booking.id,
                    aos_unit: 0,
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={Yup.object().shape({
                    platform: Yup.string().required("Message platform is not selected"),
                    message: Yup.string().when("platform", {
                        is: (value) => value && value != "whatsapp",
                        then: Yup.string().required("Message is empty"),
                    }),
                })}
                onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                    let multiChat = false;
                    let chatSendList = [
                        {
                            user_id: current_booking.user.id,
                            conversation_id: current_booking.user.conversation_id,
                            cell: values.to,
                            booking_id: current_booking.id,
                            chat_id: current_booking.chat_id,
                            name: current_booking.user.name,
                            surname: current_booking.user.surname,
                            email: current_booking.user.email,
                        },
                    ];

                    await axios({
                        method: "post",
                        url: `${process.env.REACT_APP_API_URL}get_merged_users`,
                        data: { user_id: current_booking.user.id },
                    })
                        .then((response) => {
                            if (response.data[0].length > 0) {
                                multiChat = true;
                                response.data[0].forEach(function (data) {
                                    data.user_id = data.id;
                                    data.booking_id = data?.bookings[0].id;
                                    data.chat_id = data?.bookings[0].chat_id;
                                    chatSendList.push(data);
                                });
                            }
                        })
                        .catch((error) => {
                            alert("merged users load failed");
                        });

                    async function processArray(array) {
                        for (const item of array) {
                            // console.log(
                            //   "sending " + item.name + " " + item.surname
                            // );

                            if (
                                ((values.platform == "whatsapp" || values.platform == "sms") && item.cell != null) ||
                                (values.platform == "email" && item.email != null) ||
                                values.platform == "website" ||
                                values.platform == "note"
                            ) {
                                const the_message = values.message;
                                var template = values.template;
                                var template_name = values.template_name;
                                let replaced_message = values.message;

                                let regards = "The Qliving Team";
                                if (process.env.REACT_APP_COLOUR === "UC") {
                                    regards = "The Urban Circle Team";
                                } else if (process.env.REACT_APP_COLOUR === "QP") {
                                    regards = "The Quorum Properties Team";
                                }

                                replaced_message = the_message.replace("[name]", item.name);
                                replaced_message = replaced_message.replace("[me]", current_staff?.name);
                                replaced_message = replaced_message.replace("[regards]", "Kind Regards, " + regards);
                                replaced_message = replaced_message.replace("[surname]", item.surname);
                                replaced_message = replaced_message.replace("[email]", item.email);
                                replaced_message = replaced_message.replace("[booking_reference]", current_booking?.booking_reference);
                                replaced_message = replaced_message.replace(
                                    "[edit_profile]",
                                    window.location.origin + "/edit_application/" + (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                );
                                replaced_message = replaced_message.replace("[building name]", current_booking?.property?.name);

                                replaced_message = replaced_message.replace("[room/bed]", current_booking?.unit?.name + " " + current_booking?.bed?.name);

                                replaced_message = replaced_message.replace("[unit_no]", current_booking?.unit?.name);

                                replaced_message = replaced_message.replace("[bank_details]", window.location.origin + "/bank-details/");
                                replaced_message = replaced_message.replace(
                                    "[payfast_link]",
                                    window.location.origin + "/payfast/" + (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                );

                                // replaced_message = replaced_message.replace("[choose_a_bed]", process.env.REACT_APP_LARAVEL_URL + "step3/" + current_booking?.edit_link?.edit_url+"/"+current_booking.student_type+"/"+studyyear/gender/unitid/0);
                                // replaced_message = replaced_message.replace(
                                //     "[choose_a_bed]",
                                //     process.env.REACT_APP_LARAVEL_URL +
                                //         "step3/" +
                                //         current_booking?.edit_link?.edit_url +
                                //         "/" +
                                //         current_booking?.student_type +
                                //         "/" +
                                //         current_booking?.user?.profile?.study_year +
                                //         "/" +
                                //         current_booking?.user?.profile?.gender +
                                //         "/" +
                                //         current_booking?.unit_type +
                                //         "/0"
                                // );
                                replaced_message = replaced_message.replace("[lease_link]", process.env.REACT_APP_LARAVEL_URL + "lease/" + current_booking?.lease_url);

                                replaced_message = replaced_message.replace("[lease]", process.env.REACT_APP_LARAVEL_URL + "lease/" + current_booking?.lease_url);

                                replaced_message = replaced_message.replace("[invoice_link]", process.env.REACT_APP_LARAVEL_URL + "invoice/" + current_booking?.invoice_url);
                                replaced_message = replaced_message.replace(
                                    "[documents_link]",
                                    window.location.origin + "/documents/" + (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                );
                                replaced_message = replaced_message.replace(
                                    "[move_in_form]",
                                    process.env.REACT_APP_LARAVEL_URL + "move-in-form/" + (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                );

                                // Hyperlink

                                replaced_message = replaced_message.replace(
                                    "[move_in_form_Hyperlink]",
                                    "<a href='" +
                                        process.env.REACT_APP_LARAVEL_URL +
                                        "move-in-form/" +
                                        (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url) +
                                        "'>Move In Form</a>"
                                );

                                replaced_message = replaced_message.replace(
                                    "[Move_In_Inspection_Hyperlink]",
                                    current_booking?.property_id == 1
                                        ? "<a href='" +
                                              window.location.origin +
                                              "/appointment/Move_In_Inspection/17/0/0/" +
                                              (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url) +
                                              "'>Move In Inspection</a>"
                                        : current_booking?.property_id == 2
                                        ? "<a href='" +
                                          window.location.origin +
                                          "/appointment/Move_In_Inspection/16/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url) +
                                          "'>Move In Inspection</a>"
                                        : current_booking?.property_id == 4
                                        ? "<a href='" +
                                          window.location.origin +
                                          "/appointment/Move_In_Inspection/15/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url) +
                                          "'>Move In Inspection</a>"
                                        : current_booking?.property_id == 3 || current_booking?.property_id == 6 || current_booking?.property_id == 7
                                        ? "<a href='" +
                                          window.location.origin +
                                          "/appointment/Move_In_Inspection/18/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url) +
                                          "'>Move In Inspection</a>"
                                        : current_booking?.property_id == 8
                                        ? "<a href='" +
                                          window.location.origin +
                                          "/appointment/Move_In_Inspection/27/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url) +
                                          "'>Move In Inspection</a>"
                                        : ""
                                );

                                replaced_message = replaced_message.replace(
                                    "[Move_In_Inspection]",
                                    current_booking?.property_id == 1
                                        ? window.location.origin +
                                              "/appointment/Move_In_Inspection/17/0/0/" +
                                              (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                        : current_booking?.property_id == 2
                                        ? window.location.origin +
                                          "/appointment/Move_In_Inspection/16/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                        : current_booking?.property_id == 4 || current_booking?.property_id == 11
                                        ? window.location.origin +
                                          "/appointment/Move_In_Inspection/15/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                        : current_booking?.property_id == 3 || current_booking?.property_id == 6 || current_booking?.property_id == 7
                                        ? window.location.origin +
                                          "/appointment/Move_In_Inspection/18/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                        : current_booking?.property_id == 8
                                        ? window.location.origin +
                                          "/appointment/Move_In_Inspection/27/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                        : current_booking?.property_id == 10
                                        ? window.location.origin +
                                          "/appointment/Move_In_Inspection/29/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                        : ""
                                );

                                replaced_message = replaced_message.replace(
                                    "[Move_Out_Inspection]",
                                    current_booking?.property_id == 1
                                        ? window.location.origin +
                                              "/appointment/Move_Out_Inspection/13/0/0/" +
                                              (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                        : current_booking?.property_id == 2
                                        ? window.location.origin +
                                          "/appointment/Move_Out_Inspection/12/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                        : current_booking?.property_id == 4
                                        ? window.location.origin +
                                          "/appointment/Move_Out_Inspection/11/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                        : current_booking?.property_id == 3 || current_booking?.property_id == 6 || current_booking?.property_id == 7
                                        ? window.location.origin +
                                          "/appointment/Move_Out_Inspection/14/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                        : ""
                                );
                                replaced_message = replaced_message.replace(
                                    "[Move_Out_Inspection_Hyperlink]",
                                    current_booking?.property_id == 1
                                        ? "<a href='" +
                                              window.location.origin +
                                              "/appointment/Move_Out_Inspection/13/0/0/" +
                                              (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url) +
                                              "'>Move Out Inspection</a>"
                                        : current_booking?.property_id == 2
                                        ? "<a href='" +
                                          window.location.origin +
                                          "/appointment/Move_Out_Inspection/12/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url) +
                                          "'>Move Out Inspection</a>"
                                        : current_booking?.property_id == 4
                                        ? "<a href='" +
                                          window.location.origin +
                                          "/appointment/Move_Out_Inspection/11/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url) +
                                          "'>Move Out Inspection</a>"
                                        : current_booking?.property_id == 3 || current_booking?.property_id == 6 || current_booking?.property_id == 7
                                        ? "<a href='" +
                                          window.location.origin +
                                          "/appointment/Move_Out_Inspection/14/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url) +
                                          "'>Move Out Inspection</a>"
                                        : ""
                                );

                                replaced_message = replaced_message.replace(
                                    "[Viewing]",
                                    current_booking?.property_id == 1
                                        ? window.location.origin +
                                              "/appointment/Viewing/7/0/0/" +
                                              (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                        : current_booking?.property_id == 2
                                        ? window.location.origin +
                                          "/appointment/Viewing/9/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                        : current_booking?.property_id == 4
                                        ? window.location.origin +
                                          "/appointment/Viewing/8/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                        : current_booking?.property_id == 3 || current_booking?.property_id == 6 || current_booking?.property_id == 7
                                        ? window.location.origin +
                                          "/appointment/Viewing/10/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                        : current_booking?.property_id == 8
                                        ? window.location.origin +
                                          "/appointment/Viewing/25/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                        : ""
                                );

                                replaced_message = replaced_message.replace(
                                    "[Viewing | The Ox]",
                                    window.location.origin +
                                        "/appointment/viewing/7/0/0/" +
                                        (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                );
                                replaced_message = replaced_message.replace(
                                    "[Viewing | Forest Views]",
                                    window.location.origin +
                                        "/appointment/viewing/9/0/0/" +
                                        (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                );
                                replaced_message = replaced_message.replace(
                                    "[Viewing | Delve Deeper]",
                                    window.location.origin +
                                        "/appointment/viewing/8/0/0/" +
                                        (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                );
                                replaced_message = replaced_message.replace(
                                    "[Viewing | The Unison]",
                                    window.location.origin +
                                        "/appointment/viewing/10/0/0/" +
                                        (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                );
                                replaced_message = replaced_message.replace(
                                    "[Viewing | Homage]",
                                    window.location.origin +
                                        "/appointment/viewing/25/0/0/" +
                                        (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                );
                                replaced_message = replaced_message.replace(
                                    "[Viewing | Kent]",
                                    window.location.origin +
                                        "/appointment/viewing/28/0/0/" +
                                        (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                );

                                replaced_message = replaced_message.replace(
                                    "[MaintenanceCall]",
                                    current_booking?.property_id == 1
                                        ? window.location.origin +
                                              "/appointment/MaintenanceCall/6/0/0/" +
                                              (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                        : current_booking?.property_id == 2
                                        ? window.location.origin +
                                          "/appointment/MaintenanceCall/5/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                        : current_booking?.property_id == 4
                                        ? window.location.origin +
                                          "/appointment/MaintenanceCall/4/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                        : current_booking?.property_id == 3 || current_booking?.property_id == 6 || current_booking?.property_id == 7
                                        ? window.location.origin +
                                          "/appointment/MaintenanceCall/3/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url)
                                        : ""
                                );

                                replaced_message = replaced_message.replace(
                                    "[Maintenance_Call_Hyperlink]",
                                    current_booking?.property_id == 1
                                        ? "<a href='" +
                                              window.location.origin +
                                              "/appointment/MaintenanceCall/6/0/0/" +
                                              (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url) +
                                              "'>Maintenance Call</a>"
                                        : current_booking?.property_id == 2
                                        ? "<a href='" +
                                          window.location.origin +
                                          "/appointment/MaintenanceCall/5/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url) +
                                          "'>Maintenance Call</a>"
                                        : current_booking?.property_id == 4
                                        ? "<a href='" +
                                          window.location.origin +
                                          "/appointment/MaintenanceCall/4/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url) +
                                          "'>Maintenance Call</a>"
                                        : current_booking?.property_id == 3 || current_booking?.property_id == 6 || current_booking?.property_id == 7
                                        ? "<a href='" +
                                          window.location.origin +
                                          "/appointment/MaintenanceCall/3/0/0/" +
                                          (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url) +
                                          "'>Maintenance Call</a>"
                                        : ""
                                );

                                replaced_message = replaced_message.replace(
                                    "[Documents_Hyperlink]",
                                    "<a href='" +
                                        window.location.origin +
                                        "/documents/" +
                                        (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url) +
                                        "'>Documents</a>"
                                );

                                replaced_message = replaced_message.replace(
                                    "[Edit_Profile_Hyperlink]",
                                    "<a href='" +
                                        window.location.origin +
                                        "/edit_application/" +
                                        (current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url) +
                                        "'>Edit Your Profile</a>"
                                );

                                replaced_message = replaced_message.replace(
                                    "[Invoice_Hyperlink]",
                                    "<a href='" + process.env.REACT_APP_LARAVEL_URL + "invoice/" + current_booking?.invoice_url + "'>Invoice</a>"
                                );

                                replaced_message = replaced_message.replace(
                                    "[Lease_Hyperlink]",
                                    "<a href='" + process.env.REACT_APP_LARAVEL_URL + "lease/" + current_booking?.lease_url + "'>Lease</a>"
                                );

                                // replaced_message = replaced_message.replace("[move_in_inspection]", current_booking?.property_id == 1 ? "https://calendly.com/theox-1/move-in-inspection" : current_booking?.property_id == 2  ? "https://calendly.com/forestviews/30min-1" : current_booking?.property_id == 4  ? "https://calendly.com/delvedeeper/move-in-inspection" : current_booking?.property_id == 3 || current_booking?.property_id == 6 || current_booking?.property_id == 7 ? "https://calendly.com/the-unison/move-in-inspection" : "");

                                // replaced_message = replaced_message.replace("[move_out_inspection]",  current_booking?.property_id == 1 ? "https://calendly.com/theox-1/move-out-inspection" : current_booking?.property_id == 2 ? "https://calendly.com/forestviews/move-out-inspection" : current_booking?.property_id == 4 ? "https://calendly.com/delvedeeper/move-out-inspection" : current_booking?.property_id == 3 || current_booking?.property_id == 6 || current_booking?.property_id == 7 ? "https://calendly.com/the-unison/move-out" : "");

                                // replaced_message = replaced_message.replace("[book_a_viewing]", current_booking?.property_id == 1 ? "https://calendly.com/theox-1/viewing-at-the-ox" : current_booking?.property_id == 2 ? "https://calendly.com/forestviews/book-a-viewing" : current_booking?.property_id == 4 ? "https://calendly.com/delvedeeper/viewings" : current_booking?.property_id == 3 || current_booking?.property_id == 6 || current_booking?.property_id == 7 ? "https://calendly.com/the-unison/30min" : "");

                                // replaced_message = replaced_message.replace("[move_out_custom_inspection]", customMsg);

                                var form_data = new FormData();

                                var timestamp = new Date();
                                var platform = values.platform;
                                var from = current_staff?.id;
                                var to = values.to;
                                var user_id = item.id;
                                var files = [];

                                form_data.append("timestamp", timestamp);
                                form_data.append("message", replaced_message);
                                form_data.append("conversation_id", item.conversation_id);
                                form_data.append("platform", platform);
                                form_data.append("from", from);
                                form_data.append("booking", item.booking_id);
                                form_data.append("to", item.cell);
                                form_data.append("user_id", item.user_id);
                                form_data.append("staff_id", current_staff.id);
                                form_data.append("template", template);
                                form_data.append("template_name", template_name);
                                form_data.append("type", type);
                                form_data.append("tag_id", tag_id);
                                form_data.append("aos_unit", values.aos_unit);

                                if (type === "collections") {
                                    form_data.append("collections", 1);
                                }

                                if (type === "renewals") {
                                    form_data.append("renewals", 1);
                                }

                                //Check if this is a website message, add Chat ID
                                if (platform === "website" && item.chat_id) {
                                    form_data.append("chat_id", item.chat_id);
                                }

                                // will only be one file if WA
                                if (values.files.length == 1) {
                                    template > 0 ? form_data.append("files", JSON.stringify(values.files[0])) : form_data.append("files", values.files[0]);
                                    files = values.files[0].name;
                                } else if (values.files.length > 1) {
                                    values.files.map((file) => {
                                        template > 0 ? form_data.append("files[]", JSON.stringify(file)) : form_data.append("files[]", file);
                                        files.push(file.name);
                                    });
                                }

                                if (values.platform == "note") {
                                    if (replaced_message.includes("@")) {
                                        if (!replaced_message.includes("wysiwyg-mention")) {
                                            alert("Mention not linked");
                                            return false;
                                        }
                                    }
                                }

                                await axios
                                    .post(`${process.env.REACT_APP_API_URL}send`, form_data)
                                    .then((response) => {
                                        // if (template) {
                                        //     let regards = "The Qliving Team";
                                        //     if (process.env.REACT_APP_COLOUR === "UC") {
                                        //         regards = "The Urban Circle Team";
                                        //     } else if (process.env.REACT_APP_COLOUR === "QP") {
                                        //         regards = "The Quorum Properties Team";
                                        //     }

                                        //     replaced_message = the_message.replace("[name]", item.name);
                                        //     replaced_message = replaced_message.replace("[me]", current_staff.name);
                                        //     replaced_message = replaced_message.replace("[regards]", "Kind Regards, " + regards);
                                        //     replaced_message = replaced_message.replace("[surname]", item.surname);
                                        //     replaced_message = replaced_message.replace("[email]", item.email);
                                        //     replaced_message = replaced_message.replace("[booking_reference]", current_booking?.booking_reference);
                                        //     replaced_message = replaced_message.replace(
                                        //         "[edit_profile]",
                                        //         window.location.origin + "/edit_application/" + current_booking?.edit_link !== null
                                        //             ? current_booking?.edit_link?.edit_url
                                        //             : current_booking.user.edit_url
                                        //     );
                                        // }

                                        toast(<NotificationToast title={values.platform + " successful"} message={"Your message has been sent."} />);
                                        resetForm();
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        toast(<NotificationToast title="Something went wrong" message={JSON.stringify(error.response.data)} type="error" />, {
                                            autoClose: false,
                                        });
                                    });
                            }
                        }
                    }

                    await processArray(chatSendList);

                    setSubmitting(false);
                }}
            >
                {({ values, errors, setFieldValue, handleSubmit, isValid, isSubmitting, dirty }) => (
                    <Form onSubmit={handleSubmit} className="rounded-3xl xl:absolute w-full bottom-0">
                        {mentions && mentions.length > 0 ? (
                            <>
                                <div className="bg-dark_background p-2 flex -mb-7">
                                    {mentions.map((mention) => (
                                        <ProfilePicture id={mention} size="40" className="-mr-6" />
                                    ))}
                                </div>
                            </>
                        ) : (
                            ""
                        )}

                        {current_booking.bot_flow_id > 0 && <div className="text-center font-bold text-red-500">Currently in a Bot Flow</div>}

                        <div className="flex justify-between pr-6 bg-dark_background pt-8">
                            <div className="flex pl-6 space-x-4 h-8">
                                {current_booking?.user?.cell && !current_staff.building_manager && (
                                    <>
                                        <BookingPlatformButton
                                            valuePlatform={values.platform}
                                            buttonPlatform={"whatsapp"}
                                            icon={faWhatsapp}
                                            message={"WhatsApp"}
                                            from={process.env.REACT_APP_WHATSAPP_BUSINESS_NUMBER}
                                            to={current_booking.user.cell}
                                            setFieldValue={setFieldValue}
                                        />
                                        <BookingPlatformButton
                                            valuePlatform={values.platform}
                                            buttonPlatform={"sms"}
                                            icon={faMessageLines}
                                            message={"SMS"}
                                            from={process.env.REACT_APP_WHATSAPP_BUSINESS_NUMBER}
                                            to={current_booking.user.cell}
                                            setFieldValue={setFieldValue}
                                        />
                                    </>
                                )}
                                {current_booking?.user?.email && !current_staff.building_manager && (
                                    <BookingPlatformButton
                                        valuePlatform={values.platform}
                                        buttonPlatform={"email"}
                                        icon={faEnvelope}
                                        message={"Email"}
                                        from={process.env.REACT_APP_FROM_EMAIL}
                                        to={current_booking.user.email}
                                        setFieldValue={setFieldValue}
                                    />
                                )}
                                <BookingPlatformButton
                                    valuePlatform={values.platform}
                                    buttonPlatform={"note"}
                                    icon={faNote}
                                    message={"Note"}
                                    from={current_staff?.id}
                                    to={""}
                                    setFieldValue={setFieldValue}
                                />
                                {current_booking?.chat_id > 0 && diffdays === 1 && diff < 15 && !current_staff.building_manager && (
                                    <BookingPlatformButton
                                        valuePlatform={values.platform}
                                        buttonPlatform={"website"}
                                        icon={faBrowser}
                                        message={"Website"}
                                        from={current_staff?.id}
                                        to={""}
                                        setFieldValue={setFieldValue}
                                    />
                                )}
                            </div>

                            <div>{values.platform && <BookingTemplate platform={values.platform} setFieldValue={setFieldValue} handleSubmit={handleSubmit} current_booking={current_booking} />}</div>
                        </div>
                        {values.platform === "" && <div className="px-6 py-4 block w-full sm:text-sm !outline-none resize-none bg-light_background h-full">Please choose a Platform to send from</div>}
                        {(values.platform === "email" || values.platform === "note") && (
                            <BookingWysiwyg name="message" id="message" initialValues={values} setFieldValue={setFieldValue} staff={staff} value={values.message} />
                        )}
                        {(values.platform === "whatsapp" || values.platform === "sms" || values.platform === "website") && (
                            <BookingTextArea as="textarea" rows={5} name="message" id="message" placeholder="Write your Message" errors={errors} />
                            // <BookingTextArea as="textarea" rows={5} name="message" id="message" placeholder="Write your Message" errors={errors} onKeyDown={() => setWhisperActive(true)} />
                        )}
                        {errors.message && (
                            <div className="px-6 inline-flex bg-light_background w-full">
                                <p>&nbsp;</p>
                                {errors.message && <p className="text-sm text-red-400 mr-4">{errors.message}</p>}
                            </div>
                        )}
                        <div className="px-6 py-4 ml-auto flex justify-between items-center space-x-5 bg-dark_background rounded-b-3xl">
                            <div className="flex">
                                <EmojiPicker value={values.message} source="booking" setFieldValue={setFieldValue} />
                                {values.platform !== "sms" && <BookingFileUpload setFieldValue={setFieldValue} values={values.files} max_files={values.platform === "whatsapp" ? 1 : ""} />}
                                <BookingPlaceholder setFieldValue={setFieldValue} value={values.message} platform={values.platform} />
                                <BookingWaBus setFieldValue={setFieldValue} />

                                {process.env.REACT_APP_HIDE_CHAT_TABS && process.env.REACT_APP_HIDE_CHAT_TABS === "true" ? (
                                    <BookingwaDocs setFieldValue={setFieldValue} />
                                ) : (
                                    <BookingWaBank setFieldValue={setFieldValue} />
                                )}
                            </div>
                            <div className="pr-4 ">
                                {values.platform !== "" && <SubmitButton name="Submit" disabled={!isValid || isSubmitting || !dirty} isSubmitting={isSubmitting} text="Send" />}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}
