import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory, Link } from "react-router-dom";

import Echo from "laravel-echo";
import Pusher from "pusher-js";

import { Disclosure, Dialog, Transition } from "@headlessui/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/pro-regular-svg-icons";

import {
    faUsers,
    faFileChartColumn,
    faFileInvoice,
    faCheckDouble,
    faFileMagnifyingGlass,
    faArrowUpFromSquare,
    faSquareXmark,
    faStar,
    faHome,
    faCogs,
    faSearch,
    faMeterBolt,
    faAt,
    faBuildingCircleCheck,
    faCommentsQuestion,
    faCalendar,
    faBagShopping,
    faUser,
    faRobot,
} from "@fortawesome/pro-regular-svg-icons";

import axios from "axios";
import { getStaff, signOutUser } from "../../store/auth/authActions";
import { getTemplateCategories, getTemplates, getWhatsappTemplates } from "../../store/template/templateActions";
import AppLoader from "./AppLoader";
import { getTags } from "../../store/tag/tagActions";

import ProfilePicture from "../Auth/ProfilePicture";
import StaffOnline from "../Auth/StaffOnline";
import { getBooking, getFlaggedIds } from "../../store/booking/bookingActions";
import { getProperties } from "../../store/property/propertyActions";

import { openModal } from "../../store/modal/modalReducer";
import { faCommentQuestion, faMessagePlus, faLock } from "@fortawesome/pro-solid-svg-icons";
import { getFolders } from "../../store/tag/folderActions";
import { getCollections } from "../../store/collection/collectionActions";
import { getRenewals } from "../../store/renewal/renewalActions";
import { getAppointments } from "../../store/appointments/appointmentActions";
import { getManagers } from "../../store/managers/managersActions";
import { getSettings } from "../../store/settings/settingsActions";
// import { getSender } from "./Helper";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function NavBar() {
    // const app = initializeApp(firebaseConfig);
    // const db = getFirestore(app);
    const [totalLeasing, setTotalLeasing] = useState(0);
    const [totalManagement, setTotalManagement] = useState(0);
    const [totalManagement23, setTotalManagement23] = useState(0);
    const [totalCollections, setTotalCollections] = useState(0);
    const [totalBrokers, setTotalBrokers] = useState(0);
    const [totalRenewals, setTotalRenewals] = useState(0);
    const [renewalsList, setRenewalsList] = useState([]);
    const [totalBrokersRed, setTotalBrokersRed] = useState(0);
    const [locationCount, setLocationCount] = useState({});
    // const [locationCountGreen, setLocationCountGreen] = useState({});
    // const [locationCountRed, setLocationCountRed] = useState({});
    const [closedMentions, setClosedMentions] = useState(0);

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const [totalMention, setTotalMention] = useState(false);

    let location = useLocation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    // const { tags } = useSelector((state) => state.tags);
    // const { tags_total } = useSelector((state) => state.tags);
    const { staff, current_staff } = useSelector((state) => state.auth);
    const { settings } = useSelector((state) => state.settings);
    const { renewals } = useSelector((state) => state.renewals);
    // const { staff } = useSelector((state) => state.auth);

    const [tagsList, setTagsList] = useState([]);
    const [tagsList23, setTagsList23] = useState([]);
    const [collectionsList, setCollectionsList] = useState([]);
    const [allResidents, setAllResidents] = useState(0);
    const [allResidentsRed, setAllResidentsRed] = useState(0);

    const [allResidents23, setAllResidents23] = useState(0);
    const [allResidentsRed23, setAllResidentsRed23] = useState(0);

    const [mbBalance, setMbBalance] = useState("");

    const [menu_nav, setMenuNav] = useState([
        {
            name: "All",
            icon: "home",
            href: "/booking/all",
            count: 0,
            red: "",
        },
    ]);

    var url_string = window.location.href;

    var url = new URL(url_string);
    var tag_id = url.searchParams.get("tag");
    var collection_tag = url.searchParams.get("collection_tag");
    var renewal_tag = url.searchParams.get("renewal_tag");

    //For some reason this fixes me not being able to log in
    // console.log("current_staff", current_staff);

    // if (!current_staff) {
    //     history.push("/");
    //     history.go();
    // }

    if (!current_staff?.id) {
        signout();
    }

    async function signout() {
        setLoading(true);

        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}logout`,
            headers: {
                Authorization: `Bearer ${current_staff?.token}`,
            },
        })
            .then((res) => {
                dispatch(signOutUser());
                window.location.replace("/adminlogin");
            })
            .catch((error) => {
                //Error, user probably already logged out
                console.log(error);
                dispatch(signOutUser());

                window.location.replace("/adminlogin");
            });
    }

    useEffect(() => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}balance`,
        })
            .then((response) => {
                setMbBalance(response.data.amount);
            })
            .catch((error) => {
                //Catch to stop 500 error when balance not working
                // console.log(error);
            });
    }, [location]);

    useEffect(() => {
        //Check Auth
        // console.log(current_staff?.token);
        if (current_staff?.token) {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}check_auth`,
                headers: {
                    Authorization: `Bearer ${current_staff?.token}`,
                },
            })
                .then((res) => {
                    console.log("res1", res);
                    // if (res.data === "guest") {
                    //     dispatch(signOutUser());
                    //     window.location.replace("/adminlogin");
                    // }
                })
                .catch((error) => {
                    console.log("res2", error);
                    // dispatch(signOutUser());
                    // window.location.replace("/adminlogin");
                });
        }

        axios.defaults.baseURL = process.env.REACT_APP_API_URL;

        let echo;

        if (process.env.REACT_APP_REVERB_APP_ID) {
            echo = new Echo({
                broadcaster: "reverb", // Change "pusher" to "reverb"
                key: process.env.REACT_APP_REVERB_APP_KEY,
                wsHost: process.env.REACT_APP_REVERB_URL,
                wssHost: process.env.REACT_APP_REVERB_URL,
                wsPort: process.env.REACT_APP_REVERB_PORT,
                wssPort: process.env.REACT_APP_REVERB_PORT,
                disableStats: true,
                encrypted: process.env.REACT_APP_REVERB_SCHEME === "https",
                forceTLS: process.env.REACT_APP_REVERB_SCHEME === "https",
                enableLogging: true,
            });
        } else {
            echo = new Echo({
                broadcaster: "pusher",
                key: process.env.REACT_APP_MIX_ABLY_PUBLIC_KEY,

                wsHost: process.env.REACT_APP_MIX_ABLY_URL,
                wssHost: process.env.REACT_APP_MIX_ABLY_URL,

                wsPort: process.env.REACT_APP_MIX_ABLY_PORT,
                wssPort: process.env.REACT_APP_MIX_ABLY_PORT,

                disableStats: true,
                encrypted: false,
                enabledTransports: ["ws", "wss"],
                forceTLS: false,

                enableLogging: true,

                // unavailableTimeout: 1000,
                // activityTimeout: 1000,
                // pongTimeout: 2000,
            });
        }

        echo.channel("channels.list")
            .subscribed(() => {
                console.log("You are subscribed Channels");
            })
            .listen(".channels.list", (data) => {
                const array = [];
                Object.keys(data).forEach((key) => {
                    if (key > -1) {
                        // if (data[key].name == "Archived") {
                        //     data[key].count = data[key].archived;
                        //     data[key].red = data[key].archivedRed;
                        // }
                        if (data[key].name == "Declined" || data[key].name == "Cancelled") {
                            data[key].count = data[key].declined;
                            data[key].red = data[key].declinedRed;
                        }
                        // if (data[key].name == "All Residents") {
                        //     data[key].count = data[key].residents;
                        // }

                        if (data[key].name !== "All Residents" && data[key].name !== "General") {
                            array.push(data[key]);
                        }
                    }
                });

                setTotalLeasing(data[0].countAll - data[0].residents);
                setMenuNav(array);
            });

        echo.channel("tags.list")
            .subscribed(() => {
                // console.log("You are subscribed Tags");
            })
            .listen(".tags.list", (data) => {
                const array = [];
                Object.keys(data).forEach((key) => {
                    if (key > -1) {
                        array.push(data[key]);
                    }
                });

                setAllResidents23((data?.[0]?.allres23 ?? 0) - (data?.[0]?.allResRed23 ?? 0));
                setAllResidentsRed23(data[0].allResRed23);

                setAllResidents(data?.[0]?.allres - data?.[0]?.allResRed);
                setAllResidentsRed(data?.[0]?.allResRed ?? 0);

                setTotalManagement(data[0].countAll);
                setTotalManagement23(data[0].countAll23);

                let arrayFilter = array.filter((item) => item.parent_folder === "Residents").sort((a, b) => (a.sort_order > b.sort_order ? -1 : 1));
                setTagsList(arrayFilter);

                let arrayFilter2 = array.filter((item) => item.parent_folder === "General").sort((a, b) => (a.sort_order > b.sort_order ? -1 : 1));
                setTagsList23(arrayFilter2);
            });

        if (!process.env.REACT_APP_HIDE_CHAT_TABS || process.env.REACT_APP_HIDE_CHAT_TABS === "false") {
            echo.channel("collections.list")
                .subscribed(() => {
                    // console.log("You are subscribed Tags");
                })
                .listen(".collections.list", (data) => {
                    const array = [];
                    Object.keys(data).forEach((key) => {
                        if (key > -1) {
                            array.push(data[key]);
                        }
                    });

                    setTotalCollections(data[0].countAll + data[0].ex);
                    let arrayFilter = array.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));
                    setCollectionsList(arrayFilter);
                });
        }

        if (!process.env.REACT_APP_HIDE_CHAT_TABS || process.env.REACT_APP_HIDE_CHAT_TABS === "false") {
            if (settings && settings.brokers === 1) {
                echo.channel("brokers.list")
                    .subscribed(() => {
                        console.log("You are subscribed Brokers");
                    })
                    .listen(".brokers.list", (data) => {
                        setTotalBrokers(data[0].count);
                        setTotalBrokersRed(data[0].red);
                    });
            }
        }

        if (!process.env.REACT_APP_HIDE_CHAT_TABS || process.env.REACT_APP_HIDE_CHAT_TABS === "false") {
            if (settings && settings.renewals === 1) {
                echo.channel("renewals.list")
                    .subscribed(() => {
                        console.log("You are subscribed Renewals");
                    })
                    .listen(".renewals.list", (data) => {
                        // console.log(data, "Data")
                        setTotalRenewals(data[0].countAll);
                        // setTotalBrokersRed(data[0].red);

                        const array = [];
                        Object.keys(data).forEach((key) => {
                            if (key > -1) {
                                array.push(data[key]);
                            }
                        });

                        let arrayFilter = array.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));
                        setRenewalsList(arrayFilter);
                    });
            }
        }

        echo.channel(`mentionList.${current_staff?.id}`)
            .subscribed(() => {
                // console.log("You are subscribed Mentions List");
            })
            .listen("MentionEvent", (data) => {
                setTotalMention(data[0][0].count + " | " + data[1][0].count);
            });

        if (current_staff) {
            dispatch(getStaff());
            dispatch(getProperties());
            dispatch(getTags());
            dispatch(getFolders());
            dispatch(getCollections());
            dispatch(getRenewals());
            dispatch(getAppointments());
            dispatch(getManagers());
            dispatch(getSettings());
            dispatch(getFlaggedIds());
            dispatch(getBooking());
            dispatch(getTemplates());
            dispatch(getWhatsappTemplates());
            dispatch(getTemplateCategories());

            setLoading(false);
        }

        return () => {
            echo.disconnect();
        };
        // }
    }, [dataLoaded]);

    const navigation = [
        {
            name: "All",
            icon: faHome,
            href: "/booking/all/0",
            count: 0,
        },
    ];

    if (process.env.REACT_APP_COLOUR === "UC") {
        navigation.push({
            name: "New Leads",
            icon: faCommentQuestion,
            href: "/booking/newleads/0",
            count: locationCount.NewLeads,
        });

        navigation.push({
            name: "Returning Students",
            icon: faStar,
            href: "/booking/returningstudents/0",
            count: locationCount.ReturningStudents,
        });

        navigation.push({
            name: "Applications",
            icon: faStar,
            href: "/booking/applications/0",
            count: locationCount.Applications,
        });

        navigation.push({
            name: "To Pay",
            icon: faCommentQuestion,
            href: "/booking/topay/0",
            count: locationCount.ToPay,
        });
    } else {
        navigation.push({
            name: "Unassigned",
            icon: faCommentQuestion,
            href: "/booking/unassigned/0",
            count: locationCount.Unassigned,
        });

        navigation.push({
            name: "Leads",
            icon: faStar,
            href: "/booking/leads/0",
            count: locationCount.Leads,
        });
    }

    navigation.push({
        name: "Documents",
        icon: faFileInvoice,
        href: "/booking/documents/0",
        count: locationCount.Documents,
    });

    navigation.push({
        name: "Vetting",
        icon: faFileMagnifyingGlass,
        href: "/booking/vetting/0",
        count: locationCount.Vetting,
    });

    navigation.push({
        name: "Approval",
        icon: faCheckDouble,
        href: "/booking/approval/0",
        count: locationCount.Approval,
    });

    if (process.env.REACT_APP_COLOUR === "UC") {
        navigation.push({
            name: "Lease Sent",
            icon: faCommentQuestion,
            href: "/booking/leasesent/0",
            count: locationCount.LeaseSent,
        });
        navigation.push({
            name: "Lease Signed",
            icon: faCommentQuestion,
            href: "/booking/leasesigned/0",
            count: locationCount.LeaseSigned,
        });
        navigation.push({
            name: "Bed X",
            icon: faCommentQuestion,
            href: "/booking/bedx/0",
            count: locationCount.BedX,
        });
        navigation.push({
            name: "Registration Check",
            icon: faCommentQuestion,
            href: "/booking/registrationcheck/0",
            count: locationCount.RegistrationCheck,
        });
        navigation.push({
            name: "Move In Booked",
            icon: faCommentQuestion,
            href: "/booking/moveinbooked/0",
            count: locationCount.MoveInBooked,
            // green: locationCountGreen.MoveInBooked,
            // red: locationCountRed.MoveInBooked,
        });
        navigation.push({
            name: "Moved-In",
            icon: faCommentQuestion,
            href: "/booking/movedin/0",
            count: locationCount.MovedIn,
            // green: locationCountGreen.MovedIn,
            // red: locationCountRed.MovedIn,
        });
        navigation.push({
            name: "Limbo",
            icon: faCommentQuestion,
            href: "/booking/limbo/0",
            count: locationCount.Limbo,
            // green: locationCountGreen.Limbo,
            // red: locationCountRed.Limbo,
        });
    } else {
        navigation.push({
            name: "Onboarding",
            icon: faArrowUpFromSquare,
            href: "/booking/onboarding/0",
            count: locationCount.Onboarding,
            // green: locationCountGreen.Onboarding,
            // red: locationCountRed.Onboarding,
        });
    }

    navigation.push({
        name: "All Residents",
        icon: faUsers,
        href: "/booking/residents/0",
        count: locationCount.Residents,
    });

    navigation.push({
        name: "Declined",
        icon: faSquareXmark,
        href: "/booking/declined/0",
        count: locationCount.Declined,
    });

    navigation.push({
        name: "Archived",
        icon: faLock,
        href: "/booking/archived/0",
        count: locationCount.Archived,
    });

    const navigationYou = [
        {
            name: "Mentions",
            icon: faAt,
            href: "/booking/mentions/0",
            count: locationCount.Mention ? locationCount.Mention + " | " + closedMentions : 0 + " | " + closedMentions,
        },
    ];

    let settings_navigation = [];

    if (!current_staff?.building_manager) {
        if (!current_staff?.is_plumber && !current_staff?.is_electrician) {
            if (!process.env.REACT_APP_HIDE_CHAT_TABS || process.env.REACT_APP_HIDE_CHAT_TABS === "false") {
                settings_navigation.push({ name: "Orders", icon: faBagShopping, href: "/orders/landing" });
            }

            settings_navigation.push({ name: "Reports", icon: faFileChartColumn, href: "/reports/landing" });
            settings_navigation.push({ name: "Properties", icon: faBuildingCircleCheck, href: "/properties/step1" });
            // console.log(current_staff)
            if (["QP", "QL", "UC"].includes(process.env.REACT_APP_COLOUR) && current_staff?.status === "Admin") {
                settings_navigation.push({ name: "Settings", icon: faCogs, href: "/settings" });
                settings_navigation.push({ name: "Settings New", icon: faCogs, href: "/settings_new" });
                settings_navigation.push({ name: "Users", icon: faUser, href: "/settings_users/0" });
                settings_navigation.push({ name: "Live Dashboard", icon: faRobot, href: "/reports/live_dashboard" });
            }

            if (process.env.REACT_APP_COLOUR === "QL") {
                settings_navigation.push({ name: "Meter Readings", icon: faMeterBolt, href: "/meterreadings" });
            }
        }

        settings_navigation.push({ name: "Calendar", icon: faCalendar, href: "/calendar/landing" });
    } else {
        // if (process.env.REACT_APP_COLOUR === "QL") {
        if (current_staff.create_order == 1) {
            settings_navigation.push({ name: "Orders", icon: faBagShopping, href: "/orders/landing" });
        }
        settings_navigation.push({ name: "Properties", icon: faBuildingCircleCheck, href: "/properties/step1" });
        settings_navigation.push({ name: "Calendar", icon: faCalendar, href: "/calendar/landing" });
        // }
        settings_navigation.push({ name: "Settings", icon: faCogs, href: "/settings" });
    }

    if (loading === true) return <AppLoader />;

    return (
        <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative flex-1 flex flex-col max-w-xs w-full bg-black">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => setSidebarOpen(false)}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <FontAwesomeIcon className="h-6 w-6 text-white" aria-hidden="true" icon={faTimes} />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                                <div className="flex-shrink-0 flex items-center px-4">
                                    {process.env.REACT_APP_COLOUR == "UC" ? (
                                        <img className="h-10 w-auto" src="/images/logo_uc.svg" alt="Urban Circle" />
                                    ) : (
                                        <>
                                            {process.env.REACT_APP_COLOUR == "QL" ? (
                                                <img className="h-12 w-auto mx-auto" src="/images/logo.svg" alt="Quorum Living" />
                                            ) : (
                                                <img className="h-12 w-auto mx-auto" src="/images/logo-qholdings-v2.png" alt="Quorum Properties" />
                                            )}
                                        </>
                                    )}
                                </div>
                                <nav className="mt-5 px-2 space-y-1">
                                    {menu_nav.map((item) => (
                                        <a
                                            key={"menunav_" + item.name}
                                            href={item.href}
                                            className={classNames(
                                                location.pathname === item.href ? "bg-primary_hover" : "hover:bg-primary_hover hover:text-white",
                                                "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                            )}
                                        >
                                            {/* <FontAwesomeIcon className="mr-3 flex-shrink-0 text-gray-200" aria-hidden="true" icon={item.icon} /> */}
                                            <span className="flex-1 text-gray-200">{item.name}</span>
                                            {item.count ? (
                                                <>
                                                    <span
                                                        className={classNames(
                                                            location.pathname === item.href ? "bg-black" : "bg-primary hover:bg-black",
                                                            "ml-3 inline-block py-1 px-3 text-white text-xs font-medium rounded-full"
                                                        )}
                                                    >
                                                        {item.count}
                                                    </span>
                                                </>
                                            ) : null}
                                        </a>
                                    ))}

                                    <hr />

                                    {settings_navigation.map((item) => (
                                        <a
                                            key={"sett2nav_" + item.name}
                                            href={item.href}
                                            className={classNames(
                                                location.pathname === item.href ? "bg-primary_hover" : "hover:bg-primary_hover hover:text-white",
                                                "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                            )}
                                        >
                                            <span className="flex-1 text-gray-200">{item.name}</span>
                                        </a>
                                    ))}
                                </nav>
                            </div>
                            <div className="flex-shrink-0 flex bg-black p-4">
                                <a href="#" className="flex-shrink-0 group block">
                                    <div className="flex items-center">
                                        <div>
                                            <img
                                                className="inline-block h-10 w-10 rounded-full"
                                                src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                alt=""
                                            />
                                        </div>
                                        <div className="ml-3">
                                            <ProfilePicture id={current_staff?.id} />
                                            <p className="text-sm font-medium text-gray-400 group-hover:text-gray-300">View profile</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex-1 flex flex-col pl-6 rounded-3xl min-h-0 bg-dark_background">
                    <div className="flex-1 flex flex-col pt-2 pb-4 overflow-y-auto">
                        {/* Logo */}
                        <div className="flex items-center justify-center py-4 pr-4">
                            {process.env.REACT_APP_COLOUR == "UC" ? (
                                <img className="h-14 w-auto mx-auto" src="/images/logo_uc.svg" alt="Urban Circle" />
                            ) : (
                                <>
                                    {process.env.REACT_APP_COLOUR == "QL" ? (
                                        <img className="h-12 w-auto mx-auto" src="/images/logo.svg" alt="Quorum Living" />
                                    ) : (
                                        <img className="h-12 w-auto mx-auto" src="/images/logo-qholdings-v2.png" alt="Quorum Properties" />
                                    )}
                                </>
                            )}
                        </div>

                        {/* Leasing Section */}
                        <nav className="mt-5 flex-1 space-y-1">
                            {current_staff?.status === "Admin" ? (
                                <>
                                    {mbBalance > 0 ? (
                                        <p className="text-white">
                                            Credits: <span className="text-green-400">$ {mbBalance}</span>
                                        </p>
                                    ) : (
                                        <p className="text-white">
                                            Credits: <span className="text-red-400">$ {mbBalance}</span>
                                        </p>
                                    )}
                                    <br />
                                </>
                            ) : (
                                ""
                            )}

                            {current_staff?.hideSearch != 1 && (
                                <div className="flex text-white">
                                    <button
                                        onClick={() =>
                                            dispatch(
                                                openModal({
                                                    modal_type: "ModalSearch",
                                                })
                                            )
                                        }
                                        type="button"
                                        className="hover:bg-white hover:rounded-3xl text-white group flex items-center pr-3 py-2 text-sm font-medium hover:text-dark_background"
                                    >
                                        <FontAwesomeIcon className="mr-3 w-6 flex-shrink-0 text-primary" aria-hidden="true" icon={faSearch} />
                                        <span className="flex-1 font-light">Search</span>
                                    </button>
                                </div>
                            )}

                            <div className="text-white">
                                {navigationYou.map((item) => (
                                    <Link
                                        key={"menuyounav_" + item.name}
                                        to={item.href}
                                        className={classNames(
                                            location.pathname === item.href ? "bg-white text-dark_background" : "hover:bg-white  text-white",
                                            "group flex items-center rounded-l-3xl pr-3 py-2 text-sm font-medium transition ease-linear delay-50 hover:text-dark_background "
                                        )}
                                    >
                                        <FontAwesomeIcon className="mr-3 w-6 flex-shrink-0 text-primary" aria-hidden="true" icon={item.icon} />
                                        <span className="flex-1 font-light">{item.name}</span>
                                        {totalMention ? <span className="ml-3 inline-block py-1 px-3 text-xs">{totalMention}</span> : null}
                                    </Link>
                                ))}
                            </div>

                            {current_staff?.restrict_views === 0 && current_staff?.hideLeasing == 0 ? (
                                <Disclosure as="div" className="space-y-1">
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button className="text-divider border-b-2 border-gray-400 text-sm group w-full flex items-center pr-2 py-2 text-left focus:outline-none">
                                                <svg
                                                    className={classNames(
                                                        open ? "rotate-90" : "",
                                                        "text-divider mr-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                                    )}
                                                    viewBox="0 0 20 20"
                                                    aria-hidden="true"
                                                >
                                                    <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                                                </svg>
                                                <span className="flex-1 font-light text-xs pr-4">
                                                    {process.env.REACT_APP_HIDE_CHAT_TABS && process.env.REACT_APP_HIDE_CHAT_TABS == "true" ? "LEADS" : "LEASING"}
                                                </span>
                                                <span className="ml-3 inline-block px-3 text-xs">{totalLeasing}</span>
                                            </Disclosure.Button>
                                            <Transition
                                                enter="transition duration-120 ease-out"
                                                enterFrom="transform scale-95 opacity-0"
                                                enterTo="transform scale-100 opacity-100"
                                                leave="transition duration-85 ease-out"
                                                leaveFrom="transform scale-100 opacity-100"
                                                leaveTo="transform scale-95 opacity-0"
                                            >
                                                <Disclosure.Panel className="space-y-1">
                                                    {menu_nav &&
                                                        menu_nav.length > 0 &&
                                                        menu_nav.map((item) => (
                                                            <Link
                                                                key={"menunav3_" + item.name}
                                                                to={item.href}
                                                                className={classNames(
                                                                    location.pathname === item.href ? "bg-white text-dark_background" : "hover:bg-white  text-white",
                                                                    "group flex items-center rounded-l-3xl pl-4 pr-3 py-2 text-sm font-medium transition ease-linear delay-50 hover:text-dark_background "
                                                                )}
                                                                onClick={() => dispatch(getBooking())}
                                                                // onClick={() => changeLocation(item.href.split("/").pop())}
                                                            >
                                                                {/* <FontAwesomeIcon className="mr-3 w-6 flex-shrink-0 text-primary" aria-hidden="true" icon={item.iconName} /> */}
                                                                <span className="flex-1 font-light">{item.name}</span>

                                                                {item.red && item.red > 0 ? <span className="ml-3 inline-block py-1 pl-3 text-red-400 text-xs">{item.red} | &nbsp; </span> : null}
                                                                {item.countOrange && item.countOrange > 0 ? (
                                                                    <span className="inline-block py-1 text-xs text-yellow-400">{item.countOrange} | &nbsp; </span>
                                                                ) : null}
                                                                {item.count && item.count > 0 ? <span className="inline-block py-1 text-xs text-green-400">{item.count} &nbsp; </span> : null}
                                                                {/* {item.countRed ? <span className="text-xs text-red-400">| {item.count}</span> : null} */}
                                                            </Link>
                                                        ))}
                                                </Disclosure.Panel>
                                            </Transition>
                                        </>
                                    )}
                                </Disclosure>
                            ) : (
                                ""
                            )}

                            {/* Tag Section */}
                            {current_staff?.hideResidents != 1 && (
                                <>
                                    <Disclosure as="div" className="space-y-1">
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className="text-divider border-b-2 border-gray-400 text-sm group w-full flex items-center pr-2 py-2 text-left focus:outline-none">
                                                    <svg
                                                        className={classNames(
                                                            open ? "rotate-90" : "",
                                                            "text-divider mr-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                                        )}
                                                        viewBox="0 0 20 20"
                                                        aria-hidden="true"
                                                    >
                                                        <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                                                    </svg>
                                                    <span className="flex-1 font-light text-xs pr-4">
                                                        {process.env.REACT_APP_HIDE_CHAT_TABS && process.env.REACT_APP_HIDE_CHAT_TABS == "true" ? "SALES" : "MANAGEMENT"}
                                                    </span>
                                                    {current_staff?.restrict_views === 0 ? <span className="ml-3 inline-block px-3 text-xs">{totalManagement + allResidents}</span> : ""}
                                                </Disclosure.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                >
                                                    <Disclosure.Panel>
                                                        {/* allResidents */}
                                                        {current_staff?.restrict_views === 0 ? (
                                                            <Link
                                                                key="All Residents"
                                                                to="/booking/residents"
                                                                className={classNames(
                                                                    location.pathname === "/booking/residents" ? "bg-white text-dark_background" : "hover:bg-white  text-white",
                                                                    "group flex items-center rounded-l-3xl pl-4 pr-3 py-2 text-sm font-medium transition ease-linear delay-50 hover:text-dark_background "
                                                                )}
                                                                onClick={() => dispatch(getBooking())}
                                                            >
                                                                <span className="flex-1 font-light">
                                                                    {process.env.REACT_APP_HIDE_CHAT_TABS && process.env.REACT_APP_HIDE_CHAT_TABS == "true" ? "All" : "All Residents"}
                                                                </span>
                                                                {allResidentsRed && allResidentsRed > 0 ? (
                                                                    <span className="ml-3 inline-block py-1 pl-3 text-red-400 text-xs">{allResidentsRed} | &nbsp; </span>
                                                                ) : null}
                                                                {allResidents ? <span className="inline-block py-1 text-xs text-green-400">{allResidents} &nbsp; </span> : null}
                                                            </Link>
                                                        ) : (
                                                            ""
                                                        )}

                                                        {/* tag.id === 4 */}
                                                        {tagsList?.length > 0 &&
                                                            tagsList.map((tag) => (
                                                                <>
                                                                    {current_staff?.restrict_views === 0 || (current_staff?.restrict_views === 1 && tag.id === 21) ? (
                                                                        tag.super_only == 0 ||
                                                                        (tag.super_only == 1 &&
                                                                            (current_staff.email == "roger@whitehart.co.za" ||
                                                                                current_staff.email == "rowan@qholdings.co.za" ||
                                                                                current_staff.email == "resileads@qholdings.co.za")) ? (
                                                                            <Link
                                                                                to={"/booking/management?tag=" + tag.id}
                                                                                key={"tag_" + tag.id}
                                                                                className={classNames(
                                                                                    parseInt(tag_id) === tag.id ? "bg-white  text-dark_background" : "hover:bg-white text-white",
                                                                                    "group flex rounded-l-3xl items-center pl-4 pr-3 py-2 text-sm font-medium transition ease-linear delay-50 hover:text-dark_background"
                                                                                )}
                                                                            >
                                                                                <span className="flex-1 font-light">{tag.name}</span>
                                                                                {tag.red ? <span className="ml-3 inline-block py-1 text-xs text-red-400 font-medium">{tag.red} | &nbsp; </span> : null}
                                                                                {tag.count ? <span className="inline-block py-1 text-xs text-green-400 font-medium">{tag.count}</span> : null}
                                                                            </Link>
                                                                        ) : (
                                                                            ""
                                                                        )
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </>
                                                            ))}
                                                    </Disclosure.Panel>
                                                </Transition>
                                            </>
                                        )}
                                    </Disclosure>
                                </>
                            )}

                            {/* {window.location.hostname === "localhost" ? ( */}
                            {(!process.env.REACT_APP_HIDE_CHAT_TABS || process.env.REACT_APP_HIDE_CHAT_TABS === "false") && (
                                <>
                                    {current_staff?.restrict_views === 0 ? (
                                        <Disclosure as="div" className="space-y-1">
                                            {({ open }) => (
                                                <>
                                                    <Disclosure.Button className="text-divider border-b-2 border-gray-400 text-sm group w-full flex items-center pr-2 py-2 text-left focus:outline-none">
                                                        <svg
                                                            className={classNames(
                                                                open ? "rotate-90" : "",
                                                                "text-divider mr-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                                            )}
                                                            viewBox="0 0 20 20"
                                                            aria-hidden="true"
                                                        >
                                                            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                                                        </svg>
                                                        <span className="flex-1 font-light text-xs pr-4">COLLECTIONS</span>
                                                        <span className="ml-3 inline-block px-3 text-xs">{totalCollections}</span>
                                                    </Disclosure.Button>
                                                    <Transition
                                                        enter="transition duration-100 ease-out"
                                                        enterFrom="transform scale-95 opacity-0"
                                                        enterTo="transform scale-100 opacity-100"
                                                        leave="transition duration-75 ease-out"
                                                        leaveFrom="transform scale-100 opacity-100"
                                                        leaveTo="transform scale-95 opacity-0"
                                                    >
                                                        <Disclosure.Panel>
                                                            {/* allResidents */}
                                                            <Link
                                                                key="All Residents"
                                                                to="/booking/collections?collection_tag=-1"
                                                                className={classNames(
                                                                    location.pathname === "/booking/residents" ? "bg-white text-dark_background" : "hover:bg-white  text-white",
                                                                    "group flex items-center rounded-l-3xl pl-4 pr-3 py-2 text-sm font-medium transition ease-linear delay-50 hover:text-dark_background "
                                                                )}
                                                                onClick={() => dispatch(getBooking())}
                                                            >
                                                                <span className="flex-1 font-light">All</span>
                                                                {/* {allResidentsRed && allResidentsRed > 0 ? <span className="ml-3 inline-block py-1 pl-3 text-red-400 text-xs">{allResidentsRed}  | &nbsp; </span> : null} */}
                                                                {/* {allResidents ? <span className="inline-block py-1 text-xs text-green-400">{allResidents} &nbsp; </span> : null} */}
                                                            </Link>

                                                            {collectionsList?.length > 0 &&
                                                                collectionsList.map((collection) => (
                                                                    <Link
                                                                        to={"/booking/collections?collection_tag=" + collection.id}
                                                                        key={"collection_" + collection.id}
                                                                        className={classNames(
                                                                            parseInt(collection_tag) === collection.id ? "bg-white  text-dark_background" : "hover:bg-white text-white",
                                                                            "group flex rounded-l-3xl items-center pl-4 pr-3 py-2 text-sm font-medium transition ease-linear delay-50 hover:text-dark_background"
                                                                        )}
                                                                    >
                                                                        <span className="flex-1 font-light">{collection.name}</span>
                                                                        {collection.id !== 8 ? (
                                                                            <>
                                                                                {collection.red ? (
                                                                                    <span className="ml-3 inline-block py-1 text-xs text-red-400 font-medium">{collection.red} | &nbsp; </span>
                                                                                ) : null}
                                                                                {collection.count ? (
                                                                                    <span className="inline-block py-1 text-xs text-green-400 font-medium">{collection.count}</span>
                                                                                ) : null}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {collection.redEx ? (
                                                                                    <span className="ml-3 inline-block py-1 text-xs text-red-400 font-medium">{collection.redEx} | &nbsp; </span>
                                                                                ) : null}
                                                                                {collection.ex ? <span className="inline-block py-1 text-xs text-green-400 font-medium">{collection.ex}</span> : null}
                                                                            </>
                                                                        )}
                                                                    </Link>
                                                                ))}
                                                        </Disclosure.Panel>
                                                    </Transition>
                                                </>
                                            )}
                                        </Disclosure>
                                    ) : (
                                        ""
                                    )}
                                </>
                            )}

                            {settings && settings.brokers === 1 ? (
                                <>
                                    <Disclosure as="div" className="space-y-1">
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className="text-divider border-b-2 border-gray-400 text-sm group w-full flex items-center pr-2 py-2 text-left focus:outline-none">
                                                    <svg
                                                        className={classNames(
                                                            open ? "rotate-90" : "",
                                                            "text-divider mr-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                                        )}
                                                        viewBox="0 0 20 20"
                                                        aria-hidden="true"
                                                    >
                                                        <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                                                    </svg>
                                                    <span className="flex-1 font-light text-xs pr-4">BROKERS</span>
                                                    <span className="ml-3 inline-block px-3 text-xs">{totalBrokers}</span>
                                                </Disclosure.Button>
                                                <Transition
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                >
                                                    <Disclosure.Panel>
                                                        {/* allResidents */}
                                                        <Link
                                                            key="All Residents"
                                                            to="/booking/brokers"
                                                            className={classNames(
                                                                location.pathname === "/booking/brokers" ? "bg-white text-dark_background" : "hover:bg-white  text-white",
                                                                "group flex items-center rounded-l-3xl pl-4 pr-3 py-2 text-sm font-medium transition ease-linear delay-50 hover:text-dark_background "
                                                            )}
                                                            onClick={() => dispatch(getBooking())}
                                                        >
                                                            <span className="flex-1 font-light">All</span>
                                                            {totalBrokersRed && totalBrokersRed > 0 ? (
                                                                <span className="ml-3 inline-block py-1 pl-3 text-red-400 text-xs">{totalBrokersRed} | &nbsp; </span>
                                                            ) : null}
                                                            {totalBrokers ? <span className="inline-block py-1 text-xs text-green-400">{totalBrokers} &nbsp; </span> : null}
                                                        </Link>
                                                    </Disclosure.Panel>
                                                </Transition>
                                            </>
                                        )}
                                    </Disclosure>
                                </>
                            ) : (
                                ""
                            )}

                            {(!process.env.REACT_APP_HIDE_CHAT_TABS || process.env.REACT_APP_HIDE_CHAT_TABS === "false") && (
                                <>
                                    {settings && settings.renewals === 1 ? (
                                        <>
                                            <Disclosure as="div" className="space-y-1">
                                                {({ open }) => (
                                                    <>
                                                        <Disclosure.Button className="text-divider border-b-2 border-gray-400 text-sm group w-full flex items-center pr-2 py-2 text-left focus:outline-none">
                                                            <svg
                                                                className={classNames(
                                                                    open ? "rotate-90" : "",
                                                                    "text-divider mr-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                                                )}
                                                                viewBox="0 0 20 20"
                                                                aria-hidden="true"
                                                            >
                                                                <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                                                            </svg>
                                                            <span className="flex-1 font-light text-xs pr-4">RENEWALS</span>
                                                            <span className="ml-3 inline-block px-3 text-xs">{totalRenewals}</span>
                                                        </Disclosure.Button>
                                                        <Transition
                                                            enter="transition duration-100 ease-out"
                                                            enterFrom="transform scale-95 opacity-0"
                                                            enterTo="transform scale-100 opacity-100"
                                                            leave="transition duration-75 ease-out"
                                                            leaveFrom="transform scale-100 opacity-100"
                                                            leaveTo="transform scale-95 opacity-0"
                                                        >
                                                            <Disclosure.Panel>
                                                                {/* allResidents */}
                                                                <Link
                                                                    key="All Residents"
                                                                    to="/booking/renewals?renewal_tag=-1"
                                                                    className={classNames(
                                                                        location.pathname === "/booking/renewals?renewal_tag=-1" ? "bg-white text-dark_background" : "hover:bg-white  text-white",
                                                                        "group flex items-center rounded-l-3xl pl-4 pr-3 py-2 text-sm font-medium transition ease-linear delay-50 hover:text-dark_background "
                                                                    )}
                                                                    onClick={() => dispatch(getBooking())}
                                                                >
                                                                    <span className="flex-1 font-light">All</span>
                                                                </Link>

                                                                {renewalsList?.length > 0 &&
                                                                    renewalsList.map((renewal) => (
                                                                        <Link
                                                                            to={"/booking/renewals?renewal_tag=" + renewal.id}
                                                                            key={"renewal_" + renewal.id}
                                                                            className={classNames(
                                                                                parseInt(renewal_tag) === renewal.id ? "bg-white  text-dark_background" : "hover:bg-white text-white",
                                                                                "group flex rounded-l-3xl items-center pl-4 pr-3 py-2 text-sm font-medium transition ease-linear delay-50 hover:text-dark_background"
                                                                            )}
                                                                        >
                                                                            <span className="flex-1 font-light">{renewal.name}</span>
                                                                            {renewal.red ? (
                                                                                <span className="ml-3 inline-block py-1 text-xs text-red-400 font-medium">{renewal.red} | &nbsp; </span>
                                                                            ) : null}
                                                                            {renewal.count ? <span className="inline-block py-1 text-xs text-green-400 font-medium">{renewal.count}</span> : null}
                                                                        </Link>
                                                                    ))}
                                                            </Disclosure.Panel>
                                                        </Transition>
                                                    </>
                                                )}
                                            </Disclosure>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </>
                            )}

                            {/* Tag Section */}
                            {(process.env.REACT_APP_COLOUR === "UC" || process.env.REACT_APP_HIDE_CHAT_TABS === "true") && (
                                <Disclosure as="div" className="space-y-1">
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button className="text-divider border-b-2 border-gray-400 text-sm group w-full flex items-center pr-2 py-2 text-left focus:outline-none">
                                                <svg
                                                    className={classNames(
                                                        open ? "rotate-90" : "",
                                                        "text-divider mr-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                                                    )}
                                                    viewBox="0 0 20 20"
                                                    aria-hidden="true"
                                                >
                                                    <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                                                </svg>
                                                <span className="flex-1 font-light text-xs pr-4">GENERAL</span>
                                                {current_staff?.restrict_views === 0 ? <span className="ml-3 inline-block px-3 text-xs">{totalManagement23 + allResidents23}</span> : ""}
                                            </Disclosure.Button>
                                            <Transition
                                                enter="transition duration-100 ease-out"
                                                enterFrom="transform scale-95 opacity-0"
                                                enterTo="transform scale-100 opacity-100"
                                                leave="transition duration-75 ease-out"
                                                leaveFrom="transform scale-100 opacity-100"
                                                leaveTo="transform scale-95 opacity-0"
                                            >
                                                <Disclosure.Panel>
                                                    {/* allResidents */}
                                                    {/* {current_staff?.restrict_views === 0 ? (
                                                    <Link
                                                        key="All Residents"
                                                        to="/booking/historical"
                                                        className={classNames(
                                                            location.pathname === "/booking/historical" ? "bg-white text-dark_background" : "hover:bg-white  text-white",
                                                            "group flex items-center rounded-l-3xl pl-4 pr-3 py-2 text-sm font-medium transition ease-linear delay-50 hover:text-dark_background "
                                                        )}
                                                        onClick={() => dispatch(getBooking())}
                                                    >
                                                        <span className="flex-1 font-light">All Residents</span>
                                                        {allResidentsRed23 && allResidentsRed23 > 0 ? (
                                                            <span className="ml-3 inline-block py-1 pl-3 text-red-400 text-xs">{allResidentsRed23} | &nbsp; </span>
                                                        ) : null}
                                                        {allResidents23 ? <span className="inline-block py-1 text-xs text-green-400">{allResidents23} &nbsp; </span> : null}
                                                    </Link>
                                                ) : (
                                                    ""
                                                )} */}

                                                    {/* tag.id === 4 */}
                                                    {tagsList23?.length > 0 &&
                                                        tagsList23.map((tag) => (
                                                            <>
                                                                {current_staff?.restrict_views === 0 || (current_staff?.restrict_views === 1 && tag.id === 21) ? (
                                                                    <Link
                                                                        to={"/booking/general?tag=" + tag.id}
                                                                        key={"tag2_" + tag.id}
                                                                        className={classNames(
                                                                            parseInt(tag_id) === tag.id ? "bg-white  text-dark_background" : "hover:bg-white text-white",
                                                                            "group flex rounded-l-3xl items-center pl-4 pr-3 py-2 text-sm font-medium transition ease-linear delay-50 hover:text-dark_background"
                                                                        )}
                                                                    >
                                                                        <span className="flex-1 font-light">{tag.name}</span>
                                                                        {tag.red23 ? <span className="ml-3 inline-block py-1 text-xs text-red-400 font-medium">{tag.red23} | &nbsp; </span> : null}
                                                                        {tag.count23 ? <span className="inline-block py-1 text-xs text-green-400 font-medium">{tag.count23}</span> : null}
                                                                    </Link>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </>
                                                        ))}
                                                </Disclosure.Panel>
                                            </Transition>
                                        </>
                                    )}
                                </Disclosure>
                            )}

                            {/* Online status */}
                            {staff && staff.length > 0 && (
                                <>
                                    <div className="py-4">
                                        <p className="font-light text-divider border-gray-400 border-b-2 pb-2 text-xs">ONLINE</p>
                                        <div className="py-2 flex space-x-2 flex-wrap">
                                            <StaffOnline type="online" staff={staff} styling="-mr-6" />
                                        </div>
                                    </div>

                                    {/* Online status */}
                                    <div className="py-4">
                                        <p className="font-light text-divider border-gray-400 border-b-2 pb-2 text-xs">OFFLINE</p>
                                        <div className="py-2 pr-4 flex space-x-2 flex-wrap">
                                            {/* <StaffOnline type="offline" staff={ staff } /> */}
                                            <StaffOnline type="offline" staff={staff} styling="-mr-6" />
                                        </div>
                                    </div>
                                </>
                            )}

                            {current_staff?.hideSettings != 1 && (
                                <>
                                    {!current_staff?.building_manager && !current_staff?.is_plumber && !current_staff?.is_electrician ? (
                                        <button
                                            onClick={() =>
                                                dispatch(
                                                    openModal({
                                                        modal_type: "ModalCreateApplication",
                                                    })
                                                )
                                            }
                                            type="button"
                                            className="hover:bg-white hover:rounded-3xl text-white group flex items-center pr-3 py-2 text-sm font-medium hover:text-dark_background"
                                        >
                                            <FontAwesomeIcon className="mr-3 w-6 flex-shrink-0 text-primary" aria-hidden="true" icon={faMessagePlus} />
                                            <span className="flex-1 font-light">Create a Chat</span>
                                        </button>
                                    ) : (
                                        ""
                                    )}
                                    {settings_navigation.map((item) => (
                                        <Link
                                            key={"setnav_" + item.name}
                                            to={item.href}
                                            className={classNames(
                                                location.pathname === item.href ? "bg-white rounded-l-3xl text-dark_background" : "hover:bg-white hover:rounded-l-3xl text-white",
                                                "group flex items-center pr-3 py-2 text-sm font-medium hover:text-dark_background"
                                            )}
                                        >
                                            <FontAwesomeIcon className="mr-3 w-6 flex-shrink-0 text-primary" aria-hidden="true" icon={item.icon} />
                                            <span className="flex-1 font-light">{item.name}</span>
                                        </Link>
                                    ))}
                                </>
                            )}
                        </nav>
                    </div>
                    <div className="pt-6 pb-2 border-b-2 border-gray-400" />
                    <div className="flex-shrink-0 flex py-4">
                        <a href="#" className="flex-shrink-0 w-full group block">
                            <div className="flex items-center">
                                <ProfilePicture id={current_staff?.id} />
                                <div className="ml-1">
                                    <p className="text-sm text-divider font-light uppercase">
                                        {current_staff?.name + " " + current_staff?.surname} {current_staff?.status === "Admin" && " (" + current_staff?.status + ")"}
                                    </p>
                                    <button className="text-xs  text-divider font-light group-hover:text-gray-200 uppercase" onClick={() => signout()}>
                                        Sign Out
                                    </button>

                                    <p className="text-sm text-divider font-light uppercase">V 4.19.0</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="md:pl-64 flex flex-col flex-1">
                <div className="sticky top-0 z-10 md:hidden px-4 pt-4 sm:pl-3 sm:pt-3 bg-black flex">
                    {process.env.REACT_APP_COLOUR == "UC" ? (
                        <img className="h-12 w-auto mx-auto" src="/images/logo_uc.svg" alt="Urban Circle" />
                    ) : (
                        <>
                            {process.env.REACT_APP_COLOUR == "QL" ? (
                                <img className="h-12 w-auto mx-auto" src="/images/logo.svg" alt="Quorum Living" />
                            ) : (
                                <img className="h-12 w-auto mx-auto" src="/images/logo-qholdings-v2.png" alt="Quorum Properties" />
                            )}
                        </>
                    )}
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <FontAwesomeIcon className="h-6 w-6 text-white" aria-hidden="true" icon={faBars} />
                    </button>
                </div>
            </div>
        </>
    );
}
